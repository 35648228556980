export const headerType = {
  simpleHeader: { maxHeight: 75 },
  simplehomeHeader: { maxHeight: 75 },
  simplehomeHeaderNoPadding: { maxHeight: 75 },
  multipleHeader: { maxHeight: 75 },
  multipleMenuItem: {
    height: 50,
  },
  multipleMenuWrapper: {
    height: 50,
    width: '100%',
    flex: 1,
    flexDirection: 'row'
  },
  appName: {
    height: 50,
    width: '100%',
    textAlign: 'center',
    marginTop: 9,
  },
  burgerButton: {
    position: 'absolute',
    right: 0,
    width: 61,
    zIndex: 999999,
  },
  simplehomeBurgerButton: {},
  multipleBurgerButton: {
    position: 'relative',
    right: 'auto',
    width: 61,
  },
  multipletitlesBurgerButton: {
    position: 'relative',
    right: 'auto',
    width: 61
  },
  homeButton: {
    position: 'absolute',
    left: 0,
    width: 61,
    zIndex: 999999,
  },
};

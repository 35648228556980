import React from 'react';
import { connect } from 'react-redux';
import { Header } from '../../components/header';
import { processElements, processMenu } from '../../utils/processors';

const mapElements = pages => {
  return processMenu(pages);
};

const mapStateToProps = state => {
  console.log("HEADER", state);
  return ({
    elements: mapElements((state.app || {}).pages || []),
    pressStatus: false,
    store: state,
    app: state.app,
    title: state.menuItemSelected.title || state.app.name || "",
    previousPage: state.menuItemSelected.previousPage || false,
    titleIcon: state.menuItemSelected.titleIcon || false
  })
};

export default connect(mapStateToProps)(Header);

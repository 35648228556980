import React, { Component } from 'react';
import {
  StyleSheet,
  Platform,
  Picker,
  Image,
  TextInput,
  Text,
  View,
  ScrollView,
  TouchableHighlight,
} from 'react-native';
import { Icon, Button } from 'react-native-elements';
import Accordion from 'react-native-collapsible/Accordion';
import { firebaseServiceInsert } from '../../services/firebase';
import { getStyle } from '../../styles/container';

export default class AccordionView extends Component {
  state = {
    activeSections: [],
    showSubscribeButton: true,
    showSubscribedUser: false,
    styles: getStyle('accordion', this.props.app),
    globalStyles: getStyle('globalStyles', this.props.app),
  };

  _renderSectionTitle = section => {
    return (
      <View style={this.state.styles.content}>
        <Text>{section.content}</Text>
      </View>
    );
  };

  _renderHeader = section => {
    return (
      <View style={this.state.styles.header}>
        <View style={{ flex: 1 }}>
          <Text style={this.state.globalStyles.p}> {section.datefrom}</Text>
        </View>
        <View style={{ flex: 2 }}>
          <Text
            style={[this.state.styles.headerText, this.state.globalStyles.p]}
          >
            {section.title}
          </Text>
        </View>
      </View>
    );
  };

  _renderContent = section => {
    return (
      <View style={this.state.styles.content}>
        <Image style={{ width: 100 }} source={{ uri: section.imageurl }} />
        <Text style={this.state.globalStyles.p}>
          Date/Time: {section.datefrom} to {section.dateto}
        </Text>
        <Text style={this.state.globalStyles.p}>
          Description: {section.description}
        </Text>

        <Button
          containerStyle={{}}
          onPress={() => {
            this.subscribeToEvent(section.id);
          }}
          buttonStyle={[
            this.state.showSubscribeButton && !this.state.showSubscribedUser
              ? this.state.globalStyles.primary
              : { display: 'none' },
            this.state.globalStyles.subscribeButton,
          ]}
          color={this.state.globalStyles.primary.color}
          title={section.buttonLabel || ' Subscribe'}
          accessibilityLabel={section.buttonLabel || 'Subscribe'}
          icon={() => <Icon
            name="email"
            color={this.state.globalStyles.primary.color}
            style={{ alignSelf: 'flex-start' }}
          />}
        />
        <Text
          style={
            this.state.showSubscribedUser
              ? { color: 'green', marginTop: 10 }
              : { display: 'none' }
          }
        >
          {section.successSubscribedMessage
            ? section.successSubscribedMessage
            : 'Successfully Subscribed'}
        </Text>
      </View>
    );
  };

  subscribeToEvent = eventId => {
    const that = this;
    that.setState({ showSubscribeButton: false });
    firebaseServiceInsert({
      collection: 'subscribers',
      obj: {
        event: eventId,
        name: 'subscriber',
        app: (this.props.app || {}).id,
      },
    }).then(() => {
      that.setState({ showSubscribeButton: true });
      that.setState({ showSubscribedUser: true });
    });
  };

  _updateSections = activeSections => {
    this.setState({ activeSections });
  };

  render() {
    return (
      <Accordion
        style={this.state.styles.container}
        sections={this.props.sections}
        activeSections={this.state.activeSections}
        renderHeader={this._renderHeader}
        renderContent={this._renderContent}
        onChange={this._updateSections}
      />
    );
  }
}

import React, { useState } from 'react';
import { View, Text } from 'react-native';
import { Slider } from 'react-native-elements';
import { getStyle } from '../../styles/container';

export const Range = props => {
  const globalStyles = getStyle('globalStyles', props.app);
  const [rangeValue, setRangeValue] = useState(props.element.default_value);
  return (
    <View
      style={{
        flex: 1,
        alignItems: 'stretch',
        justifyContent: 'center',
        marginBottom: 10,
        marginTop: 10,
      }}
    >
      <View style={globalStyles.tableStyle}>
        <View style={globalStyles.tableNameCell}>
          <Text>{props.element.min_label}</Text>
        </View>
        <View style={[globalStyles.tableNameCell]}>
          <Text style={globalStyles.alignRight}>{props.element.max_label}</Text>
        </View>
      </View>
      <Slider
        thumbStyle={{ height: 20, width: 20, backgroundColor: 'rgba(0,0,0,0.8)' }}
        value={parseFloat(rangeValue) || 0}
        onValueChange={value => setRangeValue(value)}
        maximumValue={parseFloat(props.element.max_value)}
        minimumValue={parseFloat(props.element.min_value)}
        step={parseFloat(props.element.step)}
      />
      <Text>Value: {rangeValue || 0}</Text>
    </View>
  );
};

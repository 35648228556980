import React, { Component } from "react";
import {
  Text,
  View,
  KeyboardAvoidingView,
  Dimensions,
  ScrollView,
  TextInput,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
import { PieChart } from "react-native-svg-charts";
import { Button } from "react-native-elements";
import { setPreviousPage } from "../../reducers/actions";

import AsyncStorage from "@react-native-community/async-storage";
import { getPrice, mapLinks, mapIcons, mapAssets } from "./utils";
import { TableContent } from "../../components/content/tableContent";

class PortfolioWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSlice: {
        label: "My portfolio",
        value: "",
      },
      labelWidth: 0,
      portfolio: [],
      assets: [],
      rates: [],
      new: {},
      defaultCurrency: "USD",
      showForm: false,
    };
    AsyncStorage.getItem("portfolio").then((portfolio) => {
      console.log("asyncport");
      this.setState({ portfolio: JSON.parse(portfolio) || [] });
    });
    AsyncStorage.getItem("rates").then((rates) =>
      this.setState({ rates: JSON.parse(rates) || [] })
    );
    AsyncStorage.getItem("assets").then((assets) => {
      assets = JSON.parse(assets);
      this.setState({
        assets: assets || [],
        new: { ...this.state.new, type: (assets || [])[0].id },
      });
    });
    AsyncStorage.getItem("defaultCurrency").then((defaultCurrency) =>
      this.setState({ defaultCurrency: defaultCurrency || "USD" })
    );
  }

  getPriceFromVal(value) {
    const currencyObj =
      this.state.rates.filter(
        (r) => r.symbol === this.state.defaultCurrency
      )[0] || {};

    return (
      (currencyObj.currencySymbol || "$") +
      (value || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  }
  render() {
    const globalStyles = this.props.globalStyles;
    const { labelWidth, selectedSlice } = this.state;
    const { label, value } = selectedSlice;
    const allColors = ["#bae8b1", "#84e47a", "#8a9489", "#c18a44", "#d28888"];
    const defaultKeyVals = {
      keys: ["Bitcoin", "Etherium", "Ripple", "GBP", "Other"],
      values: [55, 42, 35, 20, 12],
      colors: allColors,
    };

    const { keys, values, colors } =
      this.state.portfolio.length > 0
        ? this.state.portfolio.reduce(
            (acc, item, index) => {
              const asset = this.state.assets.filter(
                (asset) => asset.id === item.type
              )[0];
              const value =
                getPrice(
                  { priceUsd: (asset || {}).priceUsd || 1 },
                  this.state.rates || [],
                  this.state.defaultCurrency,
                  "number"
                ) * item.value;
              acc.keys = [...acc.keys, item.type];
              acc.values = [...acc.values, value];
              acc.colors = [...acc.colors, allColors[index]];
              return acc;
            },
            { keys: [], values: [], colors: [] }
          )
        : defaultKeyVals;
    const valuesTotal = values.reduce((acc, val) => acc + val, 0);

    const data = keys.map((key, index) => {
      const percent =
        values[index] / valuesTotal > 0.4
          ? 40
          : (values[index] / valuesTotal) * 100;
      return {
        key,
        value: values[index],
        svg: { fill: colors[index] },
        arc: {
          outerRadius: 70 + percent + "%",
          padAngle: label === key ? 0.1 : 0,
        },
        onPress: () =>
          this.setState({
            selectedSlice: { label: key, value: values[index] },
          }),
      };
    });
    const deviceWidth = Dimensions.get("window").width;
    const seeMoreButton = (
      <Button
        color="#ffffff"
        title={"View portfolio"}
        buttonStyle={[
          globalStyles.primary,
          globalStyles.centered,
          {
            backgroundColor: "rgba(255, 255, 255, 0.20)",
            width: 200,
            zIndex: 5,
            alignSelf: "center",
            borderRadius: 10,
          },
        ]}
        key={"see-more"}
        accessibilityLabel={"View portfolio"}
        onPress={() => {
          AsyncStorage.getItem("portfolio").then((portfolio) =>
            this.setState({ portfolio: JSON.parse(portfolio) || [] })
          );
          AsyncStorage.getItem("rates").then((rates) =>
            this.setState({ rates: JSON.parse(rates) || [] })
          );
          AsyncStorage.getItem("assets").then((assets) => {
            assets = JSON.parse(assets);
            this.setState({
              assets: assets || [],
              new: { ...this.state.new, type: assets[0].id },
            });
          });
          AsyncStorage.getItem("defaultCurrency").then((defaultCurrency) =>
            this.setState({ defaultCurrency: defaultCurrency || "USD" })
          );
          this.props.dispatch(
            setPreviousPage({ previousPage: this.props.pageId })
          );
        }}
      />
    );

    const removeLink = [];
    const processedData = this.state.portfolio.map((item, index) => {
      const asset = this.state.assets.filter(
        (asset) => asset.id === item.type
      )[0];
      const calculatedValue = this.getPriceFromVal(
        getPrice(
          { priceUsd: (asset || {}).priceUsd || 1 },
          this.state.rates || [],
          this.state.defaultCurrency,
          "number"
        ) * item.value
      );
      removeLink.push(() => {
        const filteredPortfolio = this.state.portfolio.filter(
          (val, i) => index !== i
        );
        AsyncStorage.setItem("portfolio", JSON.stringify(filteredPortfolio));
        this.setState({ portfolio: filteredPortfolio });
      });
      return [
        [(asset || {}).name, item.name],
        [(calculatedValue || "").toString(), "removeitem-" + index],
      ];
    });

    const formHtml = this.state.showForm ? (
      <KeyboardAvoidingView
        behavior="position"
        keyboardVerticalOffset={60}
        style={{
          flex: 2,
          position: "absolute",
          width: "100%",
          top: -30,
          marginTop: 20,
          alignSelf: "stretch",
          padding: 20,
          borderRadius: 10,
          backgroundColor: "rgba(39, 64, 38, 0.97)",
        }}
      >
        <Text
          style={[
            globalStyles.h2,
            { marginTop: 0, paddingTop: 0, marginBottom: 0 },
          ]}
        >
          Add new item
        </Text>
        <Text style={[globalStyles.h3, { marginBottom: 0 }]}>
          Select your crypto currency
        </Text>
        <Picker
          selectedValue={this.state.new.type || ""}
          style={{ height: 100 }}
          itemStyle={{
            height: 100,
            fontSize: 14,
            padding: 3,
            width: "100%",
            color: globalStyles.p.color,
          }}
          onValueChange={(itemValue, itemIndex) => {
            this.setState({ new: { ...this.state.new, type: itemValue } });
          }}
        >
          {(this.state.assets || []).map((o) => {
            const fixxx = 1;
            return o.id ? (
              <Picker.Item label={o.name} value={o.id} key={o.id} />
            ) : null;
          })}
        </Picker>
        <Text style={[globalStyles.h3, { marginTop: 15 }]}>
          Label/Name your item:
        </Text>
        <TextInput
          style={{
            height: 40,
            borderColor: "gray",
            borderWidth: 1,
            backgroundColor: "white",
            borderRadius: 5,
          }}
          key="name-label-new-portfolio"
          onChangeText={(text) => {
            this.setState({ new: { ...this.state.new, name: text } });
          }}
          defaultValue={this.state.new.name}
        />
        <Text style={[globalStyles.h3, { marginTop: 15 }]}>Amount:</Text>
        <TextInput
          style={{
            height: 40,
            borderColor: "gray",
            borderWidth: 1,
            backgroundColor: "white",
            borderRadius: 5,
          }}
          key="value-new-portfolio"
          onChangeText={(text) => {
            console.log("inputText", text);
            this.setState({ new: { ...this.state.new, value: text } });
          }}
          defaultValue={this.state.new.value}
        />
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="#ffffff"
            buttonStyle={[
              globalStyles.success,
              globalStyles.centered,
              { width: 100, borderRadius: 10 },
            ]}
            title={"Create"}
            accessibilityLabel={"Add new item"}
            onPress={() => {
              console.log("test", this.state.new);
              if (
                !this.state.new.type ||
                !this.state.new.name ||
                !this.state.new.value
              ) {
                this.setState({ error: "Please fill in all fields" });
                return;
              }
              const portfolio = [
                ...this.state.portfolio,
                {
                  name: this.state.new.name,
                  type: this.state.new.type,
                  value: parseFloat(this.state.new.value),
                },
              ];
              this.setState({
                portfolio: portfolio,
                new: { type: "bitcoin" },
                showForm: false,
                error: false,
              });
              AsyncStorage.setItem("portfolio", JSON.stringify(portfolio));
            }}
          />
          <Button
            buttonStyle={[
              globalStyles.success,
              globalStyles.centered,
              {
                backgroundColor: "rgba(256,256,256,0.1)",
                borderRadius: 10,
                alignSelf: "flex-end",
                width: 100,
                color: "white",
              },
            ]}
            containerStyle={{ backgroundColor: "transparent" }}
            color="white"
            title={"Cancel"}
            accessibilityLabel={"Cancel"}
            onPress={() => {
              this.setState({ showForm: false });
            }}
          />
        </View>
      </KeyboardAvoidingView>
    ) : (
      <Button
        color="#ffffff"
        title={"Add new item"}
        buttonStyle={[
          globalStyles.success,
          globalStyles.centered,
          {
            width: 200,
            borderRadius: 10,
            alignSelf: "center",
            marginBottom: 40,
          },
        ]}
        key={"add-new-item"}
        accessibilityLabel={"Add new item"}
        onPress={() => {
          this.setState({ showForm: true });
        }}
      />
    );
    const isNotWidget =
      this.props.previousPage || this.props.page === "portfolioFull";
    return (
      <ScrollView
        keyboardShouldPersistTaps={"handled"}
        style={{ paddingTop: 10, margin: 0, minHeight: 270 }}
      >
        <View style={{ justifyContent: "center", flex: 1 }}>
          <PieChart
            style={{ height: 200 }}
            outerRadius={"80%"}
            innerRadius={"60%"}
            data={data}
          />
          <Text
            onLayout={({
              nativeEvent: {
                layout: { width },
              },
            }) => {
              this.setState({ labelWidth: width });
            }}
            style={{
              color: globalStyles.p.color,
              position: "absolute",
              width: "100%",
              textAlign: "center",
            }}
          >
            {`${
              this.state.portfolio.length > 0 ? label : "Example"
            } \n ${this.getPriceFromVal(value || valuesTotal)}`}
          </Text>
        </View>

        {this.state.portfolio.length > 0 && isNotWidget ? (
          <TableContent
            data={processedData}
            removeLink={removeLink}
            dataLink={[]}
            globalStyles={globalStyles}
            icons={{}}
            title="Portfolio"
          />
        ) : null}
        {this.state.portfolio.length === 0 && isNotWidget ? (
          <View>
            <Text style={[globalStyles.h3, globalStyles.centered]}>
              No portfolio items found.
            </Text>
            <Text style={[globalStyles.p, globalStyles.centered]}>
              Please add items by clicking the button bellow.
            </Text>
          </View>
        ) : null}
        {!isNotWidget ? seeMoreButton : formHtml}
      </ScrollView>
    );
  }
}

export default PortfolioWidget;

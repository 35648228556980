import React, { useState } from 'react';
import { View, Text, TouchableHighlight, Linking } from 'react-native';
import { getStyle } from '../../styles/container';

export const HyperLinkElement = props => {
  const globalStyles = getStyle('globalStyles', props.app);
  const bold = props.link.bold ? globalStyles.bold : {};
  const italic = props.link.italic ? globalStyles.italic : {};

  return (
    <TouchableHighlight
      key="menu-link"
      onPress={() => {
        window.open(props.link.href, '_blank');
      }}
      underlayColor={globalStyles.mainContent.backgroundColor}
    >
      <View>
        <Text style={[globalStyles.p, globalStyles.link, bold, italic]}>
          {props.link.content || props.link.href}
        </Text>
      </View>
    </TouchableHighlight>
  );
};

import { Rating } from 'react-native-elements';
import React, { useState } from 'react';
import { View } from 'react-native';
import { firebaseServiceInsert } from '../../services/firebase';
import { getStyle } from '../../styles/container';

export const Ratings = props => {
  const globalStyles = getStyle('globalStyles', props.app);

  const ratingCompleted = rating => {
    const firebaseObj = {
      collection: 'data',
      obj: {
        app: (props.app || {}).id || 'unknown',
        pagename: props.pagename,
        dateTime: new Date(),
        data: { rating: rating },
        author_id: (props.authInfo || {}).id || 'unknown'
      },
    };
    firebaseServiceInsert(firebaseObj);
  };
  return (
    <View style={{ paddingBottom: 10, marginTop: 5, marginBottom: 10, overflow: 'hidden', height: 53 }}>
      <Rating
        fractions={0}
        startingValue={2}
        ratingCount={5}
        showRating
        type="custom"
        ratingBackgroundColor={globalStyles.mainContent.backgroundColor}
        onFinishRating={ratingCompleted}
        imageSize={30}
        style={{ paddingVertical: 10, marginTop: -52, }}
      />
    </View>
  );
};

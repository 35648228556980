import React, { useState } from 'react';
import { View, Text, TouchableHighlight, Linking, Platform } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import { Button } from 'react-native-elements';
import {
    firebaseServiceGet
} from '../../services/firebase';
import { getStyle } from '../../styles/container';
import { setContent } from '../../reducers/actions';

const addToCart = (fields, price, currency, title) => {
    AsyncStorage.getItem('cart').then((cart) => {
        cart = cart ? JSON.parse(cart) : [];
        AsyncStorage.setItem('cart', JSON.stringify([{
            fields,
            price,
            currency,
            title
        },
        ...cart]));

    })
}

export const GoToPage = (props) => {
    const globalStyles = getStyle('globalStyles', props.app);
    const atts = props.atts;
    let buttonStyleClass;
    const pageId = atts.pageId;
    switch (atts.classes) {
        case "btn btn-primary":
            buttonStyleClass = globalStyles.primary;
            break;
        case "btn btn-success":
            buttonStyleClass = globalStyles.success;
            break;
        case "btn btn-danger":
            buttonStyleClass = globalStyles.danger;
            break;
        case "btn btn-warning":
            buttonStyleClass = globalStyles.warning;
            break;
        default:
            buttonStyleClass = globalStyles.whiteButtonStyle;
            break;
    }
    return (
        <Button
            buttonStyle={[buttonStyleClass, { minWidth: 150, alignSelf: 'center' }]}
            containerStyle={{ flex: 1, justifyContent: 'center' }}
            key={atts.key}
            color={!atts.classes ? "#333333 !important" : "#ffffff"}
            title={atts.content}
            titleStyle={[globalStyles.bold, { color: !atts.classes ? "#333333 !important" : "#ffffff", fontSize: 14 }]}
            accessibilityLabel={atts.content}
            onPress={() => {
                if (atts.isAddToCart) {
                    addToCart(atts.fields, atts.price, atts.currency, atts.productTitle);
                }
                if (atts.key === 'go-to-checkout') {
                    props.dispatch(setContent({
                        elements: [{
                            element: "Header",
                            content: "Order #02381"
                        },
                        {
                            element: "Label",
                            content: "We are preparing your order for dispatch"
                        }]
                    }));
                    AsyncStorage.setItem('cart', []);
                    return;
                }
                if (pageId) {
                    firebaseServiceGet({
                        collection: 'pages',
                        docId: pageId,
                    }).then(function (page) {
                        props.dispatch(setContent(page));
                        window.top.postMessage({ type: 'navigate', key: page.id }, '*');
                        AsyncStorage.setItem('pageId', page.id);
                    });
                }
                if (atts.onPress) {
                    atts.onPress();
                }
            }}
        />
    );
};

import { StyleSheet } from 'react-native';
import { globalStyles } from './global';
import { accordion } from './accordion';
import { footer } from './footer';
import { splashPage } from './splashPage';
import { loginPage } from './loginPage';
import { video } from './video';
import { youtube } from './youtube';
import { menuOverlay } from './menuOverlay';
import { headerType } from './headerType';
import { music } from './music';
// import { social } from './socialMedia';
/*    var defaultColours = [
      { title: "All text colour", type: "text" },
      { title: "Content background colour", type: "content-bg" },
      { title: "Menu text colour", type: "menu-text" },
      { title: "Menu background colour", type: "menu-bg" },
      { title: "Menu overlay colour", type: "menu-overlay-bg" },
      { title: "Social media icons", type: "social" },
      { title: "Submit button", type: "submit" },
      { title: "Footer background colour", type: "footer-bg" },
      { title: "Footer text & icons colour", type: "footer-text" }
    ];
    */

const styles = app => {
  // declare selectors
  const colours = app?.style?.colours || {};
  const menuTextColor = colours['menu-text'];
  const menuBg = colours['menu-bg'];
  const text = colours.text;
  const contentBg = colours['content-bg'];
  const menuOverlayBg = colours['menu-overlay-bg'];
  const social = { color: colours.social };
  const footerBg = colours['footer-bg'];
  const footerText = colours['footer-text'];
  if (contentBg) {
    Object.assign(globalStyles, {
      mainContent: {
        ...globalStyles.mainContent,
        backgroundColor: contentBg,
      },
    });
    //globalStyles.mainContent.backgroundColor = app.style.colours['content-bg'];
  }
  if (menuTextColor) {
    Object.assign(globalStyles, {
      menuItemText: {
        ...globalStyles.menuItemText,
        color: menuTextColor,
      },
      menuIcon: {
        color: menuTextColor,
      },
    });
    //globalStyles.menuIcon.color = menuTextColor;
  }

  if (menuBg) {
    Object.assign(globalStyles, {
      header: {
        ...globalStyles.header,
        backgroundColor: menuBg,
      },
    });
    //globalStyles.header.backgroundColor = menuBg;
  }
  if (text) {
    Object.assign(globalStyles, {
      p: {
        ...globalStyles.p,
        color: text,
      },
      h1: {
        ...globalStyles.h1,
        color: text,
      },
      h2: {
        ...globalStyles.h2,
        color: text,
      },
      h3: {
        ...globalStyles.h3,
        color: text,
      },
      link: {
        ...globalStyles.link,
        color: text,
      },
    });

    Object.assign(menuOverlay, {
      wrap: {
        ...menuOverlay.wrap,
        color: text,
      },
      title: {
        ...menuOverlay.title,
        color: text,
      },
    });
  }
  if (menuOverlayBg) {
    Object.assign(menuOverlay, {
      wrap: {
        ...menuOverlay.wrap,
        backgroundColor: menuOverlayBg,
        color: menuTextColor,
      },
      title: {
        ...menuOverlay.title,
        color: menuTextColor,
      },
    });
  }
  if (footerBg) {
    Object.assign(footer, {
      wrap: {
        ...footer.wrap,
        backgroundColor: footerBg,
      },
    });
  }
  if (footerText) {
    Object.assign(footer, {
      wrap: {
        ...footer.wrap,
        color: footerText,
      },
    });
  }

  return {
    globalStyles,
    accordion,
    footer,
    splashPage,
    loginPage,
    video,
    youtube,
    menuOverlay,
    headerType,
    music,
    social
  };
};

export const getStyle = (name, app) => {
  return styles(app)[name];
};

import React, { useState, useEffect } from "react";
import {
  View,
  ScrollView,
  Text,
  TouchableHighlight,
  Linking,
} from "react-native";
import {
  firebaseServiceGet,
  listenFirestoreQuery,
} from "../../services/firebase";
import { setPreviousPage, setContent } from "../../reducers/actions";
import { ImageComponent } from "./image";
import { GoToPage } from "./goToPage";
import { getStyle } from "../../styles/container";
import { Icon } from "react-native-elements";
import { YouTubeElement } from "./youtube";

export const Blog = (props) => {
  const globalStyles = getStyle("globalStyles", props.app);
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState(false);
  useEffect(() => {
    listenFirestoreQuery({
      collection: "blog",
      where: ["pageId", "==", props.pageId],
      callback: (articlesResponse) => {
        setArticles(articlesResponse);
      },
    });
  }, []);

  const getArticleHtml = (article) => {
    return (
      <TouchableHighlight
        key="menu-link"
        onPress={() => {
          if (document) {
            document.querySelector("#scrollContainer").scrollTop = 0;
          }
          setArticle(article);
        }}
        underlayColor={globalStyles.mainContent.backgroundColor}
      >
        <View
          style={{ border: "1px solid #dddddd", marginBottom: 15, padding: 10 }}
        >
          {article.image ? (
            <ImageComponent src={article.image} height={100} width={"100%"} />
          ) : null}
          <Text
            style={[
              globalStyles.h3,
              globalStyles.bold,
              { marginTop: 20, marginBottom: 5 },
            ]}
          >
            {article.title.substring(0, 50)}
          </Text>
          <Text style={[globalStyles.p]}>
            {article.content.substring(0, 50)}
          </Text>
        </View>
      </TouchableHighlight>
    );
  };
  const articleHtml = article ? (
    <View>
      <TouchableHighlight
        style={{ width: 75 }}
        onPress={() => {
          setArticle(false);
        }}
        underlayColor={"#cccccc"}
        key="article-page"
      >
        <View
          style={[
            globalStyles.whiteButtonStyle,
            {
              width: 75,
              alignSelf: "flex-start",
              flexDirection: "row",
              justifyContent: "center",
              margin: 3,
              marginTop: 0,
            },
          ]}
        >
          <Icon
            type="font-awesome"
            name="angle-left"
            style={{ alignSelf: "flex-start", flex: 1 }}
          ></Icon>
          <Text
            style={[
              globalStyles.center,
              {
                alignSelf: "flex-end",
                flex: 1,
                marginBottom: 3,
                marginLeft: 5,
              },
            ]}
          >
            Back
          </Text>
        </View>
      </TouchableHighlight>

      {article.image ? (
        <ImageComponent src={article.image} height={100} width={"100%"} />
      ) : null}
      {article.youtube ? (
        <YouTubeElement videoId={article.youtube} />
      ) : null}
      <Text style={[globalStyles.h1, globalStyles.bold]}>{article.title}</Text>
      {article.content
        .replace("<br /><br />", "<br />")
        .split("<br />")
        .map((text) => (
          <Text style={[globalStyles.p]}>{text}</Text>
        ))}
    </View>
  ) : null;
  const articlesHtml = articles
    .filter((article) => !article.sticky)
    .map((article) => getArticleHtml(article));
  const articlesStickyHtml = articles
    .filter((article) => article.sticky)
    .map((article) => getArticleHtml(article));

  const allArticlesHtml = (
    <View>
      {articlesStickyHtml} {articlesHtml}
    </View>
  );
  return (
    <ScrollView>
      {!article && articles.length === 0 ? (
        <Text style={globalStyles.h3}>No articles found</Text>
      ) : null}
      {article ? articleHtml : allArticlesHtml}
    </ScrollView>
  );
};

import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { CheckBox } from "react-native-elements";
import { getStyle } from '../../styles/container';

export const CheckBoxElement = props => {
    const globalStyles = getStyle('globalStyles', props.app);
    const [checked, setChecked] = useState({});

    useEffect(() => {
        setChecked(props.options.map(option => option.correct))
    }, [props])
    return (
        <View>
            <Text style={globalStyles.h3}>{props.content}</Text>
            {(props.options || []).map((option, index) => {

                return (<CheckBox
                    title={option.text || 'Place holder option ' + (index + 1)}
                    checked={checked[index]}
                    iconLeft
                    textStyle={{ color: globalStyles.p.color }}
                    containerStyle={{ backgroundColor: globalStyles.mainContent.backgroundColor || '#ddd', marginLeft: 0 }}
                    onPress={() => {
                        const checkedNew = { ...checked };
                        checkedNew[index] = !checked[index]
                        setChecked(checkedNew);
                    }}
                />)
            })}</View>
    );
};



import React, { useEffect, useState } from 'react';
import { View, Text, TouchableHighlight, ScrollView, Linking, Image } from 'react-native';
import { parse } from 'fast-xml-parser';
import { TouchableHighlightBase } from 'react-native';
import { AsyncStorage } from 'react-native';
import { getStyle } from '../../styles/container';
import { setLoading } from '../../reducers/actions';

export const ModuleApiFeed = props => {
    const globalStyles = getStyle("globalStyles", props.app);
    const linkStyle = {
        fontSize: 18,
        color: globalStyles.p.color,
        marginBottom: 10,
        padding: 10,
        backgroundColor: '#6b6b6b38',
    };
    useEffect(() => {
        try {
            setLoading({ loading: 'fadeOutLoading' });
            fetch(props.url).then(response => response.text()).then((textResponse) => {
                let obj = parse(textResponse);
                setFeed(obj.rss.channel.item);
                setLoading({ loading: false });
            })
                .catch((error) => {
                    console.log(error);
                });
        } catch (e) {
            console.log(e);
        }


    }, [])

    const [feed, setFeed] = useState([]);
    const feedMapped = feed.map((item, index) => {
        try {
            const imageSrc = (item.description.split('src="')[1] || "").split('"')[0];

            const style = index === 0 ? { width: 400, height: 266 } : { width: 100, height: 66 }
            const textWidth = index === 0 ? 400 : '70%';
            const imageWrapWidth = index === 0 ? 400 : '30%';
            const title = index === 0 ? item.title.substring(0, 70) + (item.title.length > 70 ? '..' : '') : item.title.substring(0, 40) + (item.title.length > 40 ? '..' : '');
            return (
                <View style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginBottom: 7
                }}>
                    <TouchableHighlight onPress={() => { Linking.openURL(item.link) }} style={{ width: imageWrapWidth }} >
                        <Image source={{ uri: imageSrc }} style={style} />
                    </TouchableHighlight>
                    <TouchableHighlight onPress={() => { Linking.openURL(item.link) }} style={{ width: textWidth, height: 80 }} >
                        <View style={{ width: '100%', paddingRight: 20 }}><Text style={linkStyle}>{title}</Text></View>
                    </TouchableHighlight>
                </View>)
        } catch (e) {
            console.log(e);
            return null;
        }
    })
    return (
        <ScrollView style={{ paddingBottom: 20, flex: 1 }}>
            {feed.length > 0 ? feedMapped : null}
        </ScrollView>

    );
};

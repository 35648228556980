import firebase from "firebase";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_PROJECTID + ".firebaseapp.com",
  databaseURL:
    "https://" + process.env.REACT_APP_PROJECTID + ".firebaseio.com",
  projectId: process.env.REACT_APP_PROJECTID
};

const firebaseConfigLocal = {
  apiKey: "AIzaSyDJY0RgPrIOLPPuOaUotV6T-TEsoGMh5Gc",
  authDomain: "app-builder-stage.firebaseapp.com",
  databaseURL: "https://app-builder-stage.firebaseio.com",
  projectId: "app-builder-stage",
  storageBucket: "app-builder-stage.appspot.com",
  messagingSenderId: "899460753560",
  appId: "1:899460753560:web:e6908767a1bf549e0a08ed",
  measurementId: "G-CGBMEC0FJ7"
};

const fireapp = firebase.initializeApp(process.env.REACT_APP_LOCAL ? firebaseConfigLocal : firebaseConfig);

const firestore = fireapp.firestore();

export function firebaseServiceGetSingle(req) {
  return new Promise(async (resolve, reject) => {
    const ref = firestore.collection(req.collection).doc(req.docId);
    const doc = await ref.get();
    if (!doc.exists) {
      reject('No such document!');
    } else {
      resolve(doc.data());
    }
  })
}
// req = {collection,}
export function firebaseServiceGet(req) {
  return new Promise(async (resolve, reject) => {
    try {
      let collection = firestore.collection(req.collection);
      let response = [];
      let snapshot;
      let skipProcessing = false;
      if (!req.docId) {
        if (req.where) {
          snapshot = collection.where(req.where[0], req.where[1], req.where[2]);
        }
        if (req.whereMulti) {
          snapshot = collection;
          req.whereMulti.forEach((where, i) => {
            //console.log('where', where);
            snapshot = snapshot.where(where[0], where[1], where[2]);
          });
        }
        snapshot = snapshot.limit(req.limit || 100);
        snapshot = await snapshot.get();
      } else {
        snapshot = await collection.doc(req.docId).get();
        snapshot = [snapshot];
      }
      snapshot = snapshot || [];
      let docObj;
      snapshot.forEach(doc => {
        console.debug('[Firebase service] elements returned ');
        docObj = {};
        // console.log(doc, doc.data());
        docObj = doc.data();
        docObj.id = doc.id;
        if (!req.docId && !req.property) {
          response.push(req.property ? doc.data()[req.property] : docObj);
        } else {
          response = req.property ? doc.data()[req.property] : doc.data();
          response.id = doc.id;
        }
      });

      resolve(response);
    } catch (error) {
      console.log('Query error', error);
    }
  });
}

export function firebaseServiceInsert(req) {
  return new Promise(async (resolve, reject) => {
    //console.log('[Firebase service] inserting ', req.obj);
    let response = await firestore
      .collection(req.collection)
      .add(req.obj);
    //console.log('[Firebase service] inserting response', response);
    resolve(response);
  });
}

export function firebaseServiceInsertDoc(req, id) {
  return new Promise(async (resolve, reject) => {
    //console.log('[Firebase service] inserting doc ', req.id);
    let response = await firestore
      .collection(req.collection)
      .doc(id)
      .set(req.obj);
    //console.log('[Firebase service] inserting doc response', response);
    resolve(response);
  });
}

export function firebaseServiceUpdate(req) {
  return new Promise(async (resolve, reject) => {
    //console.log('[Firebase service] update ', req.obj);
    if (req.fieldNameArray) {
      const fieldValues = await firestore
        .collection(req.collection)
        .doc(req.docId)
        .get();
      req.obj[req.fieldNameArray].push(fieldValues);
    }
    //console.log(req.obj);
    let response = await firestore
      .collection(req.collection)
      .doc(req.docId)
      .set(req.obj, { merge: true });
    //console.log('[Firebase service] update response', response);
    resolve(response);
  });
}


export const listenFirestoreQuery = async ({
  collection,
  docId,
  callback,
  that,
  where,
  limit,
  orderBy,
  orderAscDesc
}) => {

  if (docId) {
    firestore
      .collection(collection)
      .doc(docId)
      .onSnapshot(function (doc) {
        console.log("Listen query triggered");
        const docData = doc.data();
        docData.id = doc.id;
        if (callback) callback(docData, docId, that);
      });
  } else {
    let col = firestore.collection(collection);
    if (where) {
      col = col.where(where[0], where[1], where[2]);
    }
    col = col.limit(parseInt(limit) || 10);
    if (orderBy) {
      orderAscDesc = orderAscDesc ? orderAscDesc : "desc";
      col = col.orderBy(orderBy, orderAscDesc);
    }
    col.onSnapshot(function (querySnapshot) {
      const queryArray = [];
      let docData = {};
      querySnapshot.forEach(function (doc) {
        docData = doc.data();
        docData.id = doc.id;
        queryArray.push(docData);
      });
      if (callback) callback(queryArray, that);
      console.log("Query snapshot");
    });
  }
}

//onAppleButtonPress();
//firebaseAuthService();


import React, { useState } from 'react';
import { View, Text, TouchableHighlight, Linking } from 'react-native';
import { Icon } from 'react-native-elements';
import { getStyle } from '../../styles/container';

export const EditButtons = props => {
    const globalStyles = getStyle('globalStyles', props.app);
    const atts = props.atts;
    const iconsEditStyle = { right: 62, width: 28, height: 15 };
    const iconsEditDownStyle = { right: 62, width: 28, height: 15, top: 16 }
    return (
        <View style={{ zIndex: 999999999 }}>

            <TouchableHighlight style={[globalStyles.wrapperWebIcons, iconsEditStyle]} onPress={() => { window.top.postMessage({ type: 'move-up', key: atts.key }, '*') }} nativeID={atts.key}>
                <Icon
                    name={"caret-up"}
                    type="font-awesome"
                    color={"#555"}
                    style={[globalStyles.webIconStyle, { marginTop: -10 }]}
                /></TouchableHighlight>
            <TouchableHighlight style={[globalStyles.wrapperWebIcons, iconsEditDownStyle]} onPress={() => { window.top.postMessage({ type: 'move-down', key: atts.key }, '*') }} nativeID={atts.key}>
                <Icon
                    name={"caret-down"}
                    type="font-awesome"
                    color={"#555"}
                    style={[globalStyles.webIconStyle, { marginTop: -8 }]}
                /></TouchableHighlight>

            <TouchableHighlight style={[globalStyles.wrapperWebIcons, { right: 30 }]} onPress={() => { window.top.postMessage({ type: 'edit', key: atts.key }, '*') }} nativeID={atts.key}>
                <Icon
                    name={"pencil"}
                    type="font-awesome"
                    color={"#555"}
                    style={[globalStyles.webIconStyle]}
                /></TouchableHighlight>
            <TouchableHighlight style={[globalStyles.wrapperWebIcons, { backgroundColor: 'rgba(218, 33, 33, 0.5)' }]} onPress={() => { window.top.postMessage({ type: 'remove', key: atts.key }, '*') }} nativeID={atts.key}>
                <Icon
                    name={"trash"}
                    type="font-awesome"
                    color={"white"}
                    style={[globalStyles.webIconStyle]}
                /></TouchableHighlight>
        </View>
    );
};

import { firebaseServiceInsert, firebaseServiceGet } from './firebase';

export function trackPage(user, page, app) {
  if (!app.analyticsFlag) {
    return;
  }
  let whereMulti = [];
  const today = new Date().toJSON().slice(0, 10);
  whereMulti.push(['app', '==', app]);
  whereMulti.push(['date', '==', today]);
  if (user) {
    whereMulti.push(['user', '==', user.uid]);
  }
  firebaseServiceGet({
    collection: 'analytics',
    whereMulti: whereMulti,
  }).then(response => {
    if (response.length === 0) {
      firebaseServiceInsert({
        collection: 'analytics',
        obj: {
          user: (user || {}).uid || 'unknown',
          page: page,
          app: app,
          date: today,
        },
      });
    }
  });
}

export async function getAnalyticsData(app, lastFiveDays) {
  return new Promise(async (resolve, reject) => {
    const days = {};
    const analyticsUsers = await firebaseServiceGet({
      collection: 'analytics',
      whereMulti: [
        ['app', '==', app.id],
        ['date', '>=', lastFiveDays],
      ],
    });

    analyticsUsers.forEach(event => {
      days[event.date] = (days[event.date] || 0) + 1;
    });
    resolve(days);
  });
}

import React from 'react';
import { connect } from 'react-redux';
import { Footer } from '../../components/footer';
import { processElements } from '../../utils/processors';
import { footerElementsDefault } from '../../elements';

const mapElements = footerElems => {
  return processElements(footerElems);
};

const mapAppPageStatus = state => {
  console.log('status', state);
  return state.loadAppsPage;
};
const mapStateToProps = state => ({
  elements: mapElements(footerElementsDefault || []),
  loadAppsStatus: mapAppPageStatus(state),
  app: state.app,
  menuItemSelected: state.menuItemSelected.order,
});

export default connect(mapStateToProps)(Footer);

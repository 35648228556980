import { Icon } from 'react-native-elements';
import React, { useState } from 'react';
import { View, Text, TouchableHighlight, Linking } from 'react-native';
import { getStyle } from '../../styles/container';

export const SocialMedia = props => {
  const globalStyles = getStyle('globalStyles', props.app);
  const socialStyle = getStyle('social', props.app);
  const platforms = ['facebook', 'twitter', 'youtube', 'instagram'];
  const links = platforms.map(platform => (
    <TouchableHighlight
      key="menu-link"
      onPress={() => {
        window.open(props.obj[platform], '_blank');
      }}
      underlayColor={globalStyles.mainContent.backgroundColor}
      style={{ minWidth: 50 }}
    >
      <Icon
        name={platform}
        color={socialStyle.color || globalStyles.p.color || '#336699'}
        type="font-awesome"
      />
    </TouchableHighlight>
  ));
  return (
    <View style={{ paddingBottom: 10, marginTop: 5, marginBottom: 10 }}>
      <Text style={[globalStyles.p]}>{props.obj.content}</Text>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        {links}
      </View>
    </View>
  );
};


import React, { useState } from 'react';
import { View, Text, TouchableHighlight, Linking } from 'react-native';
import { Icon } from 'react-native-elements';
import SimpleSketch, { ShapeObject } from "react-simple-sketch";
import { CirclePicker, ColorResult } from "react-color";
import { getStyle } from '../../styles/container';

export const ModuleWhiteboard = props => {
    const [state, setState] = useState({
        tool: "path",
        color: "#f44336",
        lineWidth: 4,
        objects: []
    });

    const onToolChange = value => {
        setState({
            tool: value
        });
    }
    const onColorChange = color => {
        setState({
            color: color.hex
        });
    }

    const onLineWidthChange = lineWidth => {

        setState({
            lineWidth: lineWidth.target.value
        });
    }

    const onSketchBoardChange = (objects) => {
        setState({
            ...state,
            objects
        })
    };


    const style = {};

    return (

        <div className="">
            <SimpleSketch
                tool={"path"}
                lineColor={state.color}
                lineWidth={state.lineWidth}
                width={245}
                height={400}
                style={{
                    border: "2px solid #000",
                    borderRadius: "5px",
                    backgroundColor: '#fff'
                }}
                onChange={onSketchBoardChange}
                value={state.objects}
            />
            <div>
                <CirclePicker color={state.color} onChange={onColorChange} />

            </div>
        </div>
    );

};
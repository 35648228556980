export const headerElementsDefault = [
  {
    type: "text",
    size: "h3",
    content: "",
    key: "loading-header"
  }
]

export const footerElementsDefault = [
  {
    type: "text",
    size: "normal",
    content: "",
    key: "footer-copy"
  }
]

export const contentElementsDefault = [
  {
    element: "Header",
    size: "h3",
    content: "Please select a template",
    key: "loading-content"
  }
]

import React, { useEffect, useRef, useState } from "react";

import {
  StyleSheet,
  Platform,
  ImageBackground,
  Image,
  TextInput,
  Text,
  View,
  Animated,
  ScrollView,
  TouchableHighlight,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
import { CheckBox, Icon } from "react-native-elements";
import styledNative from "styled-components/native";

import AsyncStorage from "@react-native-community/async-storage";
import messaging, {
  AuthorizationStatus,
} from "@react-native-firebase/messaging";
import { Button } from "react-native-elements";

import {
  name as appName,
  messagingTopic,
  sendingFormsEnabled,
  isWeb,
} from "../../../app-settings";
import AccordionView from "./accordion";
import Loading from "./../../containers/content/loading";
import { Ratings } from "./ratings";
import { Range } from "./range";
import { getPrice } from "../../modules/cryptocurrency/utils";
import {
  ModuleWhiteboard,
  ModuleCryptoCurrency,
  ModuleApiFeed,
} from "../../modules";
import {
  firebaseServiceGet,
  firebaseServiceInsert,
  listenFirestoreQuery
} from "../../services/firebase";
import { trackPage } from "../../services/analytics";
import { AnalyticsWidget } from "./analyticsWidget";
import { VideoElement } from "./video";
import { YouTubeElement } from "./youtube";
import { MusicElement, addNewTrack } from "./music";
import { HyperLinkElement } from "./hyperlink";
import { UploadFile } from "./uploadFile";
import { DownloadFile } from "./downloadFile";
import { CheckBoxElement } from "./checkbox";
import { ImageComponent } from "./image";
import { SocialMedia } from "./socialMedia";
import { loadHomepageContent } from "../../utils/content";
import { getStyle } from "../../styles/container";
import LoginPage from "../../containers/login-page";
import { cryptoStyle } from "../../modules/cryptocurrency/style";
import { GoToPage } from "./goToPage";
import { Cart } from "./cart";
import CarouselComp from "./carousel";
import CalendarComp from "./calendar";
import { Blog } from "./blog";
import { EditButtons } from "./editButtons";
import { ContentSlider } from "./contentSlider";
import {PlaylistElement} from "./playlist"
const onPressButton = () => {};

export const Content = ({
  elements,
  pagename,
  pageId,
  splashPageFlag,
  imageBackgroundCover,
  loadAppsPage,
  authInfo,
  app,
  dispatch,
  user,
  pageAuthentication,
  apps,
  splashPage,
  noPadding,
  previousPage,
  currentPage,
  loadingAnimation,
}) => {

  listenFirestoreQuery({
    collection: 'pages',
    docId: pageId,
    callback: async (page) => {
      if(page.elements){
        let cartsCount = 0;
        let thisCartsCount = 0;
        for(const el of (elements||[])){
          if(el.element === 'Cart') cartsCount++;
        }
        for(const el of (page.elements||[])){
          if(el.element === 'Cart') thisCartsCount++;
        }
        if(thisCartsCount > cartsCount){
          await AsyncStorage.setItem('cart', JSON.stringify([]));
        }
      }
    }
  })

  const url =
    typeof document !== "undefined"
      ? new URL(((document || {}).location || {}).href)
      : false;
  const isEditable = url ? url.searchParams.get("editable") : false;
  trackPage(user, pagename, app.id);
  if ((pageAuthentication || splashPage === "login") && !user) {
    return <LoginPage />;
  }

  const globalStyles = getStyle("globalStyles", app);
  const youtubeStyles = getStyle("youtube", app);
  const state = { dropdown: "" };

  if (app && app.pages && app.pages.length > 0 && loadAppsPage) {
    loadHomepageContent(app, dispatch);
  }

  elements = elements || [];
  const [dropdownValue, setDropdownValue] = useState("");
  const [dropdowns, setDropdowns] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [upcomingEventsInfo, setUpcomingEventsInfo] = useState(false);
  const [musicPlayerExists, setMusicPlayerExists] = useState(false);
  const [pushNotifications, setPushNotifications] = useState(
    app.uxNotifications
  );
  const [cryptoFields, setCryptoFields] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState("USD");
  const [assets, setAssets] = useState([]);
  const [rates, setRates] = useState([]);
  const [appPlaylistId, setAppPlaylistId] = useState(false)

  let submitMessage = "";
  let scrollViewPage = true;
  const inputText = {};
  const it = 0;
  const setCryptoInContent = (a) => {
    if (!a) {
      return;
    }
    console.log(a);
    try {
      a = JSON.parse(a);
    } catch (e) {
      console.log(e);
      return;
    }

    const bitcoin = a.filter((asset) => asset.id === "bitcoin")[0];
    const value = getPrice(
      { priceUsd: (bitcoin || {}).priceUsd || 1 },
      rates || [],
      defaultCurrency,
      "number-roundup-thousands"
    );

    setAssets(a);
    setCryptoFields({ threshold: "higher", price: value });
  };

  const setCryptoRatesInContent = (r) => {
    if (!r) {
      return;
    }
    try {
      r = JSON.parse(r);
    } catch (e) {
      console.log(e);
      return;
    }
    setRates(r);

    const bitcoin = assets.filter((asset) => asset.id === "bitcoin")[0];
    const value = getPrice(
      { priceUsd: (bitcoin || {}).priceUsd || 1 },
      r,
      defaultCurrency,
      "number-roundup-thousands"
    );

    setCryptoFields({ threshold: "higher", price: value });
  };
  useEffect(() => {
    AsyncStorage.getItem("alerts").then((v) => {
      setAlerts(JSON.parse(v) || []);
    });

    AsyncStorage.getItem("defaultCurrency").then((currency) => {
      setDefaultCurrency(currency || "USD");
    });

    AsyncStorage.getItem("rates").then((r) => {
      setCryptoRatesInContent(r);
    });
    AsyncStorage.getItem("assets").then((a) => {
      setCryptoInContent(a);
    });
  }, []);

  const sendForm = async (formurl, formurlBool) => {
    const data = {
      textFields: inputText,
      dropdownFields: dropdowns,
    };

    formurlBool = formurlBool || 'false';

    const firebaseObj = {
      collection: "data",
      obj: {
        app: (app || {}).id || "unknown",
        formurl,
        formurlBool,
        pagename,
        dateTime: new Date(),
        data,
        author_id: (authInfo || {}).id || "unknown",
      },
    };

    const insertIntoDB = await firebaseServiceInsert(firebaseObj);
    let cryptoMessage;
    if (formurl && formurl !== "None" && sendingFormsEnabled) {
      // send to api endpoint
      const isNotificationSubscription = formurl.startsWith("subscribe");
      if (isNotificationSubscription) {
        const formurlArray = formurl.replace("subscribe-", "").split("***");
        let fieldValue;
        let messagingTopic = formurlArray
          .map((fieldLabel) => {
            Object.entries(inputText).forEach(([key, value]) => {
              if (inputText[key].label.replace(/ /g, "-") === fieldLabel) {
                fieldValue = inputText[key].text;
              }
            });
            Object.entries(dropdowns).forEach(([key, value]) => {
              if (dropdowns[key].label.replace(/ /g, "-") === fieldLabel) {
                fieldValue = dropdowns[key].selectedValue;
              }
            });

            return fieldValue;
          })
          .join("-");

        if (cryptoFields.threshold || cryptoFields.price) {
          messagingTopic +=
            "-" +
            (cryptoFields.threshold || "higher") +
            "-" +
            cryptoFields.price;
        }

        cryptoMessage = "Alert set";

        AsyncStorage.getItem("alerts").then((alerts) => {
          messagingTopic = messagingTopic + (defaultCurrency || "USD");
          messaging()
            .subscribeToTopic(messagingTopic)
            .then(() => console.log("Subscribed to topic: ", messagingTopic));
          alerts = JSON.parse(alerts || "[]");
          setAlerts([...alerts, messagingTopic]);
          AsyncStorage.setItem(
            "alerts",
            JSON.stringify([...alerts, messagingTopic])
          );
        });
      }
    }
    setLoading(false);
    setMessage(
      submitMessage || cryptoMessage || "Thank you for your submission!"
    );
    setTimeout(() => {
      setMessage("");
    }, 2500);
  };

  if (!elements || elements.length === 0) {
    return <ScrollView style={globalStyles.mainContent} />;
  }
  let trackCount = 0;
  let allArtwork = [];
  let allArtists = [];
  let allTitles = [];
  const elementsMapped = elements.map((t) => {
    let element;
    if (t.type == "other") {
      element = <Text key={t.key || Math.random()}>{t.content}</Text>;
    }
    if (t.type == "analyticsWidget") {
      element = <AnalyticsWidget app={app} apps={apps} />;
    }
    if (t.type == "socialmedia") {
      element = <SocialMedia obj={t} app={app} />;
    }
    if (t.type == "range") {
      element = <Range key={t.key || Math.random()} element={t} />;
    }
    if (t.type == "youtube") {
      element = <YouTubeElement videoId={t.content} />;
    }
    if (t.type == "video") {
      element = <VideoElement content={t.content} />;
    }
    if (t.type == "blog") {
      element = <Blog dispatch={dispatch} pageId={pageId} atts={t} app={app} />;
    }
    if (t.type == "hyperlink") {
      element = <HyperLinkElement link={t} app={app} />;
    }
    if (t.type === "uploadfile") {
      element = <UploadFile />;
    }
    if (t.type === "downloadfile") {
      element = <DownloadFile src={t.src} content={t.content} />;
    }
    if (t.type == "music") {
      trackCount++;
      allArtwork.push(t.artwork);
      allArtists.push(t.artist);
      allTitles.push(t.title);
      element = (
        <MusicElement
          isEditable={isEditable}
          trackCount={trackCount}
          url={t.url}
          key={t.key}
          title={t.title}
          track={t.track}
          artist={t.artist}
          artwork={t.artwork}
          allArtwork={allArtwork}
          allArtists={allArtists}
          allTitles={allTitles}
        />
      );
    }
    if (t.type == "ratings") {
      element = (
        <Ratings
          key={t.key}
          app={app}
          pagename={pagename}
          authInfo={authInfo}
        />
      );
    }
    if (t.type == "break") {
      element = (
        <View key={t.key || Math.random()} style={{ padding: 15 }}></View>
      );
    }
    if (t.type == "upcomingevents") {
      if (upcomingEvents.length === 0) {
        firebaseServiceGet({
          collection: "events",
          where: ["app", "==", app.id],
        }).then((response) => {
          if (response.length > 0) {
            setUpcomingEvents(
              response.map((event) => {
                event.title = event.name;
                return event;
              })
            );
          }
        });
      }

      element = (
        <View key={t.key}>
          <Text style={globalStyles.h3}>Upcoming Events</Text>
          <AccordionView sections={upcomingEvents} app={app} />
        </View>
      );
    }
    if (t.type == "text") {
      const bold = t.bold ? globalStyles.bold : {};
      const italic = t.italic ? globalStyles.italic : {};
      if (t.size == "h1") {
        element = (
          <Text key={t.key} style={[globalStyles.h1, bold, italic]}>
            {t.content}
          </Text>
        );
      }
      if (t.size == "h2") {
        element = (
          <Text key={t.key} style={[globalStyles.h2, bold, italic]}>
            {t.content}
          </Text>
        );
      }
      if (t.size == "h3") {
        element = (
          <Text key={t.key} style={[globalStyles.h3, bold, italic]}>
            {t.content}
          </Text>
        );
      }
      if (t.size == "p") {
        element = (
          <Text key={t.key} style={[globalStyles.p, bold, italic]}>
            {t.content}
          </Text>
        );
      }
    }
    if (t.type == "textinput") {
      inputText[t.key] = {text: '', label: t.label};
      element = (
        <View key={t.key}>
          <Text style={globalStyles.label}>{t.label}</Text>
          <TextInput
            style={{
              height: 40,
              borderColor: "gray",
              borderWidth: 1,
              backgroundColor: "white",
            }}
            key={t.key}
            onChangeText={(text) => {
              inputText[t.key].text = text;
              inputText[t.key].label = t.label;
            }}
            defaultValue={inputText[t.key].text}
          />
        </View>
      );
    }
    if (t.type == "textarea") {
      inputText[t.key] = {text: '', label: t.label};
      element = (
        <View key={t.key}>
          <Text style={globalStyles.label}>{t.label}</Text>
          <TextInput
            style={{
              height: 100,
              borderColor: "gray",
              borderWidth: 1,
              backgroundColor: "white",
            }}
            multiline
            numberOfLines={4}
            key={t.key}
            onChangeText={(text) => {
              inputText[t.key].text = text;
              inputText[t.key].label = t.label;
            }}
            defaultValue={inputText[t.key].text}
          />
        </View>
      );
    }
    if (t.type == "submit") {
      let buttonStyleClass;

      submitMessage = t.message;
      switch (t.classes) {
        case "btn btn-primary":
          buttonStyleClass = globalStyles.primary;
          break;
        case "btn btn-success":
          buttonStyleClass = globalStyles.success;
          break;
        case "btn btn-danger":
          buttonStyleClass = globalStyles.danger;
          break;
        case "btn btn-warning":
          buttonStyleClass = globalStyles.warning;
          break;
        default:
          buttonStyleClass = globalStyles.primary;
          break;
      }
      element = (
        <Button
          buttonStyle={[
            buttonStyleClass,
            globalStyles.buttonShadow,
            globalStyles.centered,
            { width: 200, borderRadius: 10, marginTop: 0 },
          ]}
          key={t.key}
          color="#ffffff"
          title={t.content}
          accessibilityLabel={t.content}
          onPress={() => {
            sendForm(t.formurl, t.formurlBool);
          }}
        />
      );
    }

    if (t.type === "cart") {
      element = <Cart app={app} dispatch={dispatch} element={t} />;
    }
    if (t.type === "gotopage") {
      const fields = {
        textFields: Object.keys(inputText)
          .filter((key) => (t.cartFields || []).includes(key))
          .reduce((prevObj, key) => {
            prevObj[key] = inputText[key];
            return prevObj;
          }, {}),
        dropdownFields: Object.keys(dropdowns)
          .filter((key) => (t.cartFields || []).includes(key))
          .reduce((prevObj, key) => {
            prevObj[key] = dropdowns[key];
            return prevObj;
          }, {}),
      };
      element = <GoToPage atts={t} fields={fields} dispatch={dispatch} />;
    }
    if (t.type === "checkboxes") {
      element = <CheckBoxElement content={t.content} options={t.options} />;
    }
    if (t.type === "carousel") {
      element = (
        <CarouselComp
          dispatch={dispatch}
          data={t.data}
          height={t.height}
          pageId={t.pageId}
        />
      );
    }
    if (t.type === "calendar") {
      const calenderProps = {
        paddingTop: t.paddingTop,
        backgroundColor: t.backgroundColor,
        fontColor: t.fontColor,

        buttonBackgroundColor: t.buttonBackgroundColor,
        buttonHoverBackgroundColor: t.buttonHoverBackgroundColor,
        buttonFontColor: t.buttonFontColor,
        buttonHoverFontColor: t.buttonHoverFontColor,

        buttonWeekendBackgroundColor: t.buttonWeekendBackgroundColor,
        buttonWeekendFontColor: t.buttonWeekendFontColor,

        buttonNeighboringMonthBackgroundColor: t.buttonNeighboringMonthBackgroundColor,
        buttonNeighboringMonthFontColor: t.buttonNeighboringMonthFontColor,

        buttonTodayBackgroundColor: t.buttonTodayBackgroundColor,
        buttonTodayFontColor: t.buttonTodayFontColor,

        buttonActiveBackgroundColor: t.buttonActiveBackgroundColor,
        buttonActiveFontColor: t.buttonActiveFontColor,

      }
      element = <CalendarComp id={'calendar'+t.key} {...calenderProps} />;
    }
    if (t.type == "dropdown") {
      if (!dropdowns[t.key]) {
        dropdowns[t.key] = {
          label: t.content,
          selectedValue: (t.options || [{ value: "" }])[0].value,
        };
        setDropdowns(dropdowns);
      }
      const options = t.options || [
        { text: "No options entered", value: "none", key: "optionsnone" },
      ];
      const cryptoIncrements = { btc: 1000, ltc: 100, eth: 100 };
      const cryptoCurrencySizeFields =
        t.content === "Select a currency" ? (
          <View style={{ flex: 2 }}>
            <Picker
              selectedValue={(cryptoFields || {}).threshold || "higher"}
              style={{ maxHeight: 100, marginTop: -10 }}
              itemStyle={[
                cryptoStyle.picker,
                {
                  maxHeight: 90,
                  color: globalStyles.p.color,
                },
              ]}
              onValueChange={(itemValue, itemIndex) => {
                setCryptoFields({ ...cryptoFields, threshold: itemValue });
              }}
            >
              <Picker.Item
                label={"> higher than"}
                value={"higher"}
                key={"higher"}
              />
              <Picker.Item
                label={"< lower than"}
                value={"lower"}
                key={"lower"}
              />
            </Picker>
          </View>
        ) : null;

      const cryptoPriceLabel =
        t.content === "Select a currency" ? (
          <View
            key={t.key + "cryptopricelabel"}
            style={cryptoStyle.priceContainer}
          >
            <Text
              style={[globalStyles.h3, { textAlign: "left", paddingLeft: 5 }]}
            >
              {defaultCurrency || "USD"}
            </Text>
            <Text style={[globalStyles.h3, { paddingRight: 5 }]}>
              {parseFloat(cryptoFields.price || 0).toFixed(2) || "0"}
            </Text>
          </View>
        ) : null;

      const cryptoPriceField =
        t.content === "Select a currency" ? (
          <View
            key={t.key + "cryptoprice"}
            style={cryptoStyle.plusMinusContainer}
          >
            <TouchableHighlight
              style={cryptoStyle.plusMinusIcons}
              key={t.key + "cryptoplus"}
              onPress={() => {
                const currentPrice = parseFloat(cryptoFields.price || 0);
                const price =
                  currentPrice +
                  (cryptoIncrements[dropdownValue.toLowerCase()] || 1000);
                setCryptoFields({ ...cryptoFields, price: price });
              }}
            >
              <Text
                style={[
                  globalStyles.label,
                  globalStyles.centered,
                  globalStyles.whiteColor,
                  { fontWeight: "bold" },
                ]}
              >
                +
              </Text>
            </TouchableHighlight>
            <TouchableHighlight
              style={cryptoStyle.plusMinusIcons}
              key={t.key + "crypto"}
              onPress={() => {
                const currentPrice = parseFloat(cryptoFields.price || 0);
                const price =
                  currentPrice -
                  (cryptoIncrements[dropdownValue.toLowerCase()] || 1000);
                setCryptoFields({ ...cryptoFields, price: price });
              }}
            >
              <Text
                style={[
                  globalStyles.label,
                  globalStyles.centered,
                  globalStyles.whiteColor,
                  { fontWeight: "bold" },
                ]}
              >
                -
              </Text>
            </TouchableHighlight>
          </View>
        ) : null;

      //default dropdown
      element = (
        <View
          style={{ flex: 1, flexDirection: "column", alignContent: "center" }}
        >
          <View style={{ flex: 1, flexDirection: "column" }}>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                width: "100%",
                marginBottom: 5,
              }}
            >
              <Text
                style={[
                  {
                    fontSize: 16,
                    color: globalStyles.p.color,
                    height: isWeb ? "auto" : 70,
                  },
                ]}
              >
                {t.content}
              </Text>
            </View>

            <View style={{ flex: 2, justifyContent: "center" }}>
              <Picker
                selectedValue={
                  dropdowns[t.key].selectedValue || t.options[0].value
                }
                style={{ maxHeight: 100, margin: 0 }}
                itemStyle={{
                  maxHeight: 100,
                  margin: 0,
                  fontSize: 14,
                  padding: 3,
                  width: "100%",
                  color: globalStyles.p.color,
                }}
                onValueChange={(itemValue, itemIndex) => {
                  setDropdownValue(itemValue);
                  const dropdownValues = dropdowns;

                  dropdownValues[t.key] = {
                    label: t.content,
                    selectedLabel: 1,
                    selectedValue: itemValue,
                  };
                  setDropdowns(dropdownValues);
                  if (t.content === "Select a currency" && assets && rates) {
                    const asset = assets.filter(
                      (asset) => asset.symbol.toLowerCase() === itemValue
                    )[0];
                    if (!asset) {
                      return;
                    }
                    let roundup =
                      asset.id === "bitcoin" ? "thousands" : "hundreds";

                    const value = getPrice(
                      { priceUsd: (asset || {}).priceUsd || 1 },
                      rates || [],
                      defaultCurrency,
                      "number-roundup-" + roundup
                    );

                    setCryptoFields({ threshold: "higher", price: value });
                  }
                }}
              >
                {(options || []).map((o) => {
                  const fixxx = 1;
                  return o.text ? (
                    <Picker.Item label={o.text} value={o.value} key={o.key} />
                  ) : null;
                })}
              </Picker>
            </View>
          </View>
          {isWeb ? null : (
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                height: 100,
                justifyContent: "center",
              }}
            >
              {cryptoCurrencySizeFields}
              {cryptoPriceLabel}
              {cryptoPriceField}
            </View>
          )}
        </View>
      );
    }
    if (t.type === "notificationButtons") {
      element = (
        <View>
          <Text style={[{ color: "black" }, globalStyles.h2]}>
            Receive push notifications:
          </Text>
          <CheckBox
            title="On"
            checkedIcon="dot-circle-o"
            uncheckedIcon="circle-o"
            checked={pushNotifications}
            iconLeft
            textStyle={{ color: globalStyles.p.color }}
            containerStyle={{
              backgroundColor:
                globalStyles.mainContent.backgroundColor || "#ddd",
            }}
            onPress={() => {
              setPushNotifications(true);
              messaging().subscribeToTopic(messagingTopic);
            }}
          />
          <CheckBox
            title="Off"
            checkedIcon="dot-circle-o"
            uncheckedIcon="circle-o"
            checked={!pushNotifications}
            iconLeft
            textStyle={{ color: globalStyles.p.color }}
            containerStyle={{
              backgroundColor:
                globalStyles.mainContent.backgroundColor || "#ddd",
            }}
            onPress={() => {
              messaging().unsubscribeFromTopic(messagingTopic);
              setPushNotifications(false);
            }}
          />
        </View>
      );
    }
    if (t.type == "image") {
      element = (
        <ImageComponent
          app={app}
          src={t.src}
          height={t.height}
          width={t.width}
        />
      );
    }
    if (t.type === "whiteboard") {
      element = <ModuleWhiteboard pageId={pageId} dispatch={dispatch} />;
    }
    if (t.type === "contentslider") {
      element = (
        <ContentSlider
          app={app}
          data={t.data}
          category={t.category}
          appId={app.id}
          title={t.content}
          dispatch={dispatch}
        />
      );
    }
    if (t.type === "crypto") {
      if (t.page !== "alerts") {
        scrollViewPage = false;
      }
      element = (
        <ModuleCryptoCurrency
          app={app}
          currentPage={currentPage}
          alerts={alerts}
          setAlerts={setAlerts}
          setCryptoRatesInContent={setCryptoRatesInContent}
          setCryptoInContent={setCryptoInContent}
          previousPage={previousPage}
          pageId={pageId}
          dispatch={dispatch}
          page={t.page}
          disableInterval={t.disableInterval}
        />
      );
    }
    if (t.type === "apifeed") {
      element = <ModuleApiFeed url={t.url} app={app} />;
    }
    if(t.type === 'playlist'){
      element = <PlaylistElement
          key={t.key}
          app={app}
          id={t.id}
          playlist={t.playlist}
          isEditable={isEditable}
          appPlaylistId={appPlaylistId}
          setAppPlaylistId={setAppPlaylistId}
      />
    }
    if (isEditable) {
      return (
        <View style={[globalStyles.wrapperWeb]}>
          <EditButtons atts={t} />
          {element}
        </View>
      );
    } else {
      return <View style={globalStyles.wrapperEl}>{element}</View>;
    }
  });

  const imageSource = {};
  const messageHtml = message ? (
    <Text key="message" style={globalStyles.messageSuccess}>
      {message}
    </Text>
  ) : null;
  const elementsHtml = elementsMapped;

  const noPaddingStyle = noPadding
    ? { paddingTop: 20, paddingBottom: 20, paddingLeft: 0, paddingRight: 0 }
    : {};

  globalStyles.mainContent.backgroundColor = imageBackgroundCover
    ? "transparent"
    : globalStyles.mainContent.backgroundColor;
  const scrollViewHtml = !scrollViewPage ? (
    <View style={[globalStyles.mainContent, noPaddingStyle]}>
      {elementsHtml}
      {messageHtml}
    </View>
  ) : (
    <ScrollView
      style={[globalStyles.mainContent, noPaddingStyle]}
      nativeID={"scrollContainer"}
    >
      {elementsHtml}
      {messageHtml}
    </ScrollView>
  );
  const viewHtml = (
    <View
      style={[
        globalStyles.mainContent,
        { backgroundColor: "transparent", padding: 0 },
      ]}
    >
      {elementsHtml}
      {messageHtml}
    </View>
  );

  const contentHtml = splashPage === "login" ? viewHtml : scrollViewHtml;

  return contentHtml;
};

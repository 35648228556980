export const mainBackgroundColor = 'transparent';

export const globalStyles = {
    h3: {
        fontSize: 16,
        marginBottom: 7,
        marginTop: 10,
    },
    h2: {
        fontSize: 20,
        marginBottom: 10,
        marginTop: 15,
    },
    whiteColor: {
        color: 'white'
    },
    title: {
        fontSize: 40,
        marginBottom: 10,
    },
    h1: {
        fontSize: 24,
        marginBottom: 10,
    },
    marginTop: {
        marginTop: 10,
    },
    centered: {
        textAlign: 'center',
        alignItems: 'center'
    },
    p: {
        marginTop: 10,
        fontSize: 14,
        marginBottom: 15,
    },
    menuWrapper: {
        flex: 1,
        flexDirection: 'row',
        height: 75,
    },
    menuItem: {
        minWidth: '20%',
        height: 75,
        paddingTop: 22,
    },
    menuItemSelected: {
        backgroundColor: 'rgba(255, 255, 255, 0.11)'
    },

    menuItemText: {
        color: '#333333',
        textAlign: 'center',
        minWidth: '20%',
        maxWidth: 90,
        height: 75,
        fontSize: 12,
        marginTop: 3,
        overflow: 'hidden'
    },
    menuIcon: {
        color: '#333333'
    },
    header: {
        paddingBottom: 5,
        paddingTop: 25,
        flex: 1,
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        maxHeight: 100,
        width: '100%'
    },
    mainContent: {
        flex: 1,
        color: '#333333',
        flexDirection: 'column',
        padding: 20,
        backgroundColor: mainBackgroundColor,
    },
    strech: {
        width: '100%',
        height: '100%',
        padding: 5,
        borderColor: '#fff',
        borderWidth: 3,
    },
    primary: {
        color: '#ffffff',
        backgroundColor: '#337ab7',
        marginTop: 5,
    },
    success: {
        color: '#ffffff',
        backgroundColor: '#5cb85c',
        marginTop: 5,
    },
    danger: {
        color: '#ffffff',
        backgroundColor: '#d9534f',
        marginTop: 5,
    },
    warning: {
        color: '#ffffff',
        backgroundColor: '#f0ad4e',
        marginTop: 5,
    },
    messageSuccess: {
        backgroundColor: '#156d15',
        marginTop: 5,
        marginBottom: 5,
        padding: 5,
        color: '#ffffff',
        borderColor: '#054205',
        borderWidth: 1,
    },
    tableStyle: {
        flex: 1,
        alignSelf: 'stretch',
        flexDirection: 'row',
        marginTop: 10,
        fontSize: 18,
    },
    tableDateCell: { flex: 1, alignSelf: 'stretch', padding: 7 },
    tableDate: { color: '#333', fontSize: 18 },
    tableNameCell: { flex: 2, alignSelf: 'stretch', padding: 7 },
    tableName: { color: '#000', fontSize: 18 },
    alignRight: { textAlign: 'right', alignSelf: 'stretch' },
    subscribeButton: {
        alignSelf: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 20,
        paddingLeft: 12,
        paddingRight: 12,
        borderRadius: 3,
    },
    link: {
        marginTop: 5,
        marginBottom: 10,
        textDecorationLine: 'underline',
        color: '#336699'
    },
    bold: {
        fontWeight: 'bold'
    },
    italic: {
        fontStyle: 'italic'
    },
    noPaddingTop: {
        paddingTop: 0,
    },
    littlePaddingTop: {
        paddingTop: 5,
    },
    tabs: {

        textDecorationLine: 'none',
        width: 60,
        borderWidth: 1,
        display: "flex",
        marginTop: 10,
        borderColor: 'rgba(255, 255, 255, 0.11)', borderRadius: 18, padding: 8, overflow: "hidden", marginRight: 10, backgroundColor: "rgba(255, 255, 255, 0.11)", textAlign: 'center'
    },
    tabsHover: {
        backgroundColor: "yellow", color: 'black'
    },
    darkListItem: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: "rgba(0, 0, 0, 0.3)"
    },
    whiteButtonStyle: {
        color: '#333333',
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowOpacity: 0.8,
        elevation: 6,
        shadowRadius: 7,
        shadowOffset: { width: 1, height: 2 },
        margin: 15, marginTop: 30,
        padding: 8, alignSelf: 'center',
        backgroundColor: '#ffffff', width: 170,
        borderRadius: 5
    },
    outlineButtonStyle: {
        alignSelf: 'center',
    },
    buttonShadow: {
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowOpacity: 0.8,
        elevation: 6,
        shadowRadius: 7,
        shadowOffset: { width: 1, height: 2 },
        marginBottom: 15,
        alignSelf: 'center'
    },
    wrapperWeb: {
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: '#ddd',
        borderRadius: 1,
        marginTop: 1,
        padding: 2
    },
    wrapperWebIcons: {
        position: 'absolute',
        right: 0,
        padding: 3,
        zIndex: 99999,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#ccc',
        borderRadius: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.80)'
    },
    webIconStyle: {
    }
};

const url = new URL(document.location.href);
const appId = url.searchParams.get("app");
const appName = url.searchParams.get("appName");

module.exports = {
  name: appName,
  displayName: appName,
  appId: appId,
  isTesterApp: false,
  messagingTopic: "crypto-prices",
  package: "premium",
  notificationsToggle: true,
  isSignInDisabled: true,
  sendingFormsEnabled: true,
  showTitleText: true,
  cryptoBuyLink: "https://buy.paybis.com/click?pid=1731&offer_id=1",
  isWeb: true,
};

export const accordion = {
  container: {
    flex: 1,
    backgroundColor: 'rgba(220, 220, 220, 0.1)',
    paddingTop: 0,
    paddingBottom: 5,
  },
  title: {
    textAlign: 'left',
    fontWeight: '300',
    marginBottom: 20,
  },
  header: {
    backgroundColor: 'rgba(220, 220, 220, 0.2)',
    padding: 10,
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: '#dddddd',
    borderRightWidth: 1,
    borderRightColor: '#dddddd',
    borderLeftWidth: 1,
    borderLeftColor: '#dddddd',
    borderBottomWidth: 1,
    borderBottomColor: '#dddddd'
  },
  headerText: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: '500'
  },
  content: {
    borderLeftWidth: 1,
    borderLeftColor: '#dddddd',
    borderRightWidth: 1,
    borderRightColor: '#dddddd',
    borderBottomWidth: 1,
    borderBottomColor: '#dddddd',
    padding: 20,
  },
  active: {
    backgroundColor: 'rgba(255,255,255,0.5)'
  },
  inactive: {
    backgroundColor: 'rgba(245,252,255,1)'
  },
  selectors: {
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'center'
  },
  selector: {
    padding: 10,
  },
  activeSelector: {
    fontWeight: 'bold'
  },
  selectTitle: {
    fontSize: 14,
    fontWeight: '500',
    padding: 10,
  },
  multipleToggle: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 30,
    alignItems: 'center'
  },
  multipleToggle__title: {
    fontSize: 16,
    marginRight: 8,
  },
};

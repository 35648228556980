
import React, { useState } from 'react';
import { Dimensions } from 'react-native';
import { View, StyleSheet } from 'react-native';
import { createElement } from "react-native-web";
import { getStyle } from "../../styles/container";

export const VideoElement = props => {
    const videoStyles = getStyle('video', props.app);
    if(!videoStyles.video.width){
        videoStyles.video.width = '100%';
    }
    const attrs = {
        style: videoStyles.video,
        src: props.content,
        //width: Dimensions.get('window').width - 40,
        controls: "controls"
    }
    const videoEl = createElement("video", attrs);
    return <View style={[videoStyles.video, { height: 140, position: 'relative' }]}> {videoEl}</View>
}
import React, { useState, useEffect, useRef } from 'react';
import { View, ScrollView, Animated, Easing, Dimensions, Text, ImageBackground } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import messaging, {
    AuthorizationStatus,
} from '@react-native-firebase/messaging';
import { TableContent } from '../../components/content/tableContent';

export const Alerts = (props) => {

    const removeLink = []
    const processedData = (props.alerts || []).map((alert, index) => {
        const messagingTopic = alert;
        alert = alert.split('-');
        removeLink.push(() => {
            const filteredAlerts = props.alerts.filter((val, i) => index !== i);
            AsyncStorage.setItem('alerts', JSON.stringify(filteredAlerts));
            props.setAlerts(filteredAlerts);
            messaging()
                .unsubscribeFromTopic(messagingTopic)
                .then(() => console.log('Unsubscribed to topic!', messagingTopic));
        });
        const [coin, threshold, price] = alert;

        return [[coin.toUpperCase(), threshold], [price, "removeitem-" + index]]
    });
    useEffect(() => {
        AsyncStorage.getItem('rates').then(r => {
            props.setCryptoRatesInContent(r);
        });
        AsyncStorage.getItem('assets').then(a => {
            props.setCryptoInContent(a);
        });
    }, [])
    return <View style={{ flex: 1 }}>
        <TableContent data={processedData} removeLink={removeLink} dataLink={[]} globalStyles={props.globalStyles} icons={{}} title="My Alerts" />
    </View>
}
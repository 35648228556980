import React, { useState, useEffect } from 'react';
import { View, Text, TouchableHighlight, Linking, Platform } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import { Button } from 'react-native-elements';
import { getStyle } from '../../styles/container';
import { TableContent } from './tableContent';
import { GoToPage } from './goToPage';

export const Cart = (props, dispatch) => {
    const globalStyles = getStyle('globalStyles', props.app);
    const [cartContent, setCart] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        AsyncStorage.getItem('cart').then(cart => {
            if (!cart) {
                cart = [];
            }
            cart = JSON.parse(cart);
            setCart(cart);
            setTotal(cart[0].currency + '' + cart.reduce((accumulator, item) => accumulator + parseFloat(item.price), 0))
        });
    }, [props])

    const removeLinkFunc = cartContent.map((item, index) => () => {

        AsyncStorage.getItem('cart').then((cartContent) => {
            if (!cartContent) {
                return;
            }
            cartContent = JSON.parse(cartContent);
            const cart = cartContent.filter((filterItem, flterIndex) => index !== flterIndex);
            AsyncStorage.setItem('cart', JSON.stringify(cart));
            setCart(cart);
            setTotal(cart[0].currency + '' + cart.reduce((accumulator, item) => accumulator + parseFloat(item.price), 0))
        });
    });
    const emptyCart = <View>
        <Text style={[globalStyles.h1, globalStyles.bold]}>YOUR CART IS EMPTY</Text>
        <Text style={globalStyles.p}>You have no items in your cart at the moment</Text>
        <GoToPage dispatch={props.dispatch} atts={{ content: "Start Shopping", classes: props.element.classes || "btn btn-danger", pageId: (((props.app || {}).pages || [])[0] || {}).id, key: "go-home" }} />
    </View>;

    const fullCart = <View >
        <Text style={[globalStyles.h1, globalStyles.bold, { marginBottom: -20, marginTop: 20 }]}>Cart</Text>
        <TableContent
            color={'#333'} data={cartContent.map((item, i) => [[item.title, item.currency + item.price], ['removeitem-' + i]])}
            removeLink={removeLinkFunc}
            globalStyles={globalStyles} icons={[]} />
        <Text style={[globalStyles.h3, globalStyles.bold]}>Total: {total}</Text>
        <GoToPage dispatch={props.dispatch} atts={{ content: "Pay and order", classes: props.element.classes || "btn btn-danger", pageId: 'checkout', key: "go-to-checkout" }} />
        <GoToPage dispatch={props.dispatch} atts={{ content: "Back", pageId: (((props.app || {}).pages || [])[0] || {}).id, key: "go-home" }} />

    </View>
    return (
        <View>

            {cartContent.length > 0 ? fullCart : emptyCart}
        </View>
    );
};

export function processElements(elements) {
  let i = 0;
  console.log('elements.length', elements);
  if (!elements || elements.length === 0) {
    return [];
  }
  console.log('elements', elements);
  let elProcessed = elements.map(el => {
    i++;
    if (el.element == 'Image') {
      return {
        key: el.id,
        type: 'image',
        src: el.src,
        width: el.width,
        height: el.height
      };
    }
    if (el.element == 'Paragraph') {
      return {
        key: el.id,
        type: 'text',
        bold: el.bold,
        italic: el.italic,
        content: el.content,
        size: 'p'
      };
    }
    if (el.element == 'Label') {
      return {
        key: el.id,
        type: 'text',
        bold: el.bold,
        italic: el.italic,
        content: el.content,
        size: 'h3'
      };
    }
    if (el.element == 'Header') {
      return {
        key: el.id,
        type: 'text',
        bold: el.bold,
        italic: el.italic,
        content: el.content,
        size: 'h1'
      };
    }
    if (el.element == 'TextInput') {
      return {
        key: el.id,
        type: 'textinput',
        label: el.content,
        required: el.required,
      };
    }

    if (el.element == 'Checkboxes') {
      //console.log("el11", el);
      return {
        key: el.id,
        type: 'checkboxes',
        content: el.content,
        options: el.options,
        required: el.required,
      };
    }
    if (el.element == 'Textarea') {
      return {
        key: el.id,
        type: 'textarea',
        label: el.content,
        required: el.required,
      };
    }
    if (el.element == 'Dropdown') {
      return {
        key: el.id,
        type: 'dropdown',
        content: el.content,
        options: el.options,
        required: el.required,
      };
    }
    if (el.element == 'SubmitButton') {
      return {
        key: el.id,
        type: 'submit',
        message: el.message,
        content: el.content,
        formurl: el.formurl,
        formurlBool: el.formurlBool,
        classes: el.classes,
      };
    }
    if (el.element == 'GoToPage') {
      return {
        key: el.id,
        type: 'gotopage',
        content: el.content,
        classes: el.classes,
        pageId: el.pageId,
        ...el
      };
    }
    if (el.element == 'Blog') {
      return {
        type: "blog",
        key: el.id,
        ...el
      };
    }
    if (el.element == 'Cart') {
      return {
        type: "cart",
        key: el.id,
        ...el
      };
    }
    if (el.element == 'Carousel') {
      return {
        type: "carousel",
        key: el.id,
        data: el.data,
        ...el
      };
    }
    if (el.element == 'UpcomingEvents') {
      return {
        key: el.id,
        type: 'upcomingevents'
      };
    }
    if (el.element == 'Break') {
      return {
        key: el.id,
        type: 'break'
      };
    }
    if (el.element == 'Radio') {
      return {
        key: el.id,
        type: 'radio',
        content: el.content,
        options: el.options,
        required: el.required,
      };
    }
    if (el.element == 'Rating') {
      return {
        key: el.id,
        type: 'ratings',
        content: el.content,
        required: el.required,
      };
    }
    if (el.element == 'Range') {
      return {
        key: el.id,
        type: 'range',
        content: el.label,
        max_value: el.max_value,
        max_label: el.max_label,
        min_value: el.min_value,
        min_label: el.min_label,
        default_value: el.default_value,
        step: el.step,
        required: el.required,
      };
    }
    if (el.element == 'YouTube') {
      return {
        key: el.id,
        type: 'youtube',
        content: el.content,
      };
    }
    if (el.element == 'Video') {
      return {
        key: el.id,
        type: 'video',
        content: el.content,
      };
    }
    if (el.element == 'Music') {
      return {
        key: el.id,
        type: 'music',
        artist: el.artist,
        artwork: el.artwork,
        url: el.url,
        title: el.title,
        content: el.content,
      };
    }
    if (el.element == 'SocialMedia') {
      return {
        key: el.id,
        type: 'socialmedia',
        content: el.content,
        facebook: el.facebook,
        twitter: el.twitter,
        youtube: el.youtube,
        instagram: el.instagram,
      };
    }
    if (el.element == 'HyperLink') {
      return {
        key: el.id,
        type: 'hyperlink',
        content: el.content,
        href: el.href,
        bold: el.bold,
        italic: el.italic
      };
    }
    if (el.element == 'Calendar') {
      return {
        key: el.id,
        type: 'calendar',
        ...el
      };
    }
    if (el.element == 'UploadFile') {
      return {
        key: el.id,
        type: 'uploadfile'
      };
    }
    if (el.element == 'DownloadFile') {
      return {
        key: el.id,
        type: 'downloadfile',
        src: el.src,
        content: el.content
      };
    }
    if (el.element == 'Whiteboard') {
      return {
        key: el.id,
        type: 'whiteboard'
      };
    }
    if (el.element == 'CryptoCurrency') {
      return {
        key: el.id,
        type: 'crypto',
        page: el.page,
        disableInterval: el.disableInterval
      };
    }
    if (el.type === 'notificationButtons') {
      return { key: 'pushnot', type: 'notificationButtons' };
    }
    if (el.element === 'analytics') {
      return { key: 'analyticsWidget', type: 'analyticsWidget' };
    }
    if (el.element === 'ApiFeed') {
      return { key: 'apifeed', type: 'apifeed', url: el.url };
    }
    if (el.element === 'ContentSlider') {
      return { ...el, key: el.id, type: 'contentslider' };

    }
    if (el.element === 'Playlist') {
      return { ...el, key: el.id, type: 'playlist' };
    }

    return {
      key: el.id,
      type: 'other',
      content: el.element || 'Unknown'
    };
  });
  elProcessed = elProcessed.filter(el => el);
  return elProcessed;
}

export function processMenu(pages) {

  return (pages || []).map(p => {
    return {
      type: 'text',
      key: 'menu-' + p.id,
      pageId: p.id || 'no-id',
      content: p.menuTitle || 'No title',
      size: 'h3',
      icon: p.icon || ''
    };
  });
}

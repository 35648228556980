import React, { useState, setState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableHighlight,
  Linking,
  ScrollView,
} from 'react-native';
import { Icon } from 'react-native-elements';
import { BarChart, XAxis, YAxis, Grid } from 'react-native-svg-charts';
import * as scale from 'd3-scale';
import * as shape from 'd3-shape';
import { setHours, format } from 'date-fns';
import { getStyle } from '../../styles/container';
import { getAnalyticsData } from '../../services/analytics';

export const AnalyticsWidget = props => {
  const globalStyles = getStyle('globalStyles', props.app);
  const menuOverlayStyle = getStyle('menuOverlay', props.app);
  const emptyDays = { empty: 1 };
  let day;
  let i = 0;
  let firstDay;
  do {
    day = new Date();
    day = day.setDate(day.getDate() - i);
    //day = day.toJSON().slice(0, 10);
    day = new Date(day).toJSON().slice(0, 10);
    emptyDays[day] = 0.01;
    firstDay = day;
    i++;
  } while (i < 6);
  const [days, setDaysState] = useState(emptyDays);
  useEffect(() => {
    if (days.empty > 0) {
      getAnalyticsData(props.app, firstDay).then(res => {
        Object.keys(days).forEach((item, i) => {
          res[item] = res[item] || 0.1;
        });
        res.empty = 0;
        setDaysState(res);
      });
    }
  });
  i = 0;
  let dataGraph = [];
  let lastDate = 0;
  const daysStats = Object.keys(days)
    .sort()
    .filter(day => day > firstDay)
    .map(day => {
      if (day === 'empty') {
        return null;
      }
      dataGraph[i] = days[day];
      lastDate = days[day];
      i++;
      return (
        <View style={[globalStyles.menuItem]} key={'days-stats-' + i}>
          <Text style={[globalStyles.h4, globalStyles.centered]}>
            {day.split('-')[2]}/{day.split('-')[1]}
          </Text>
          <Text style={[globalStyles.h3, globalStyles.centered]}>
            {parseInt(days[day])}
          </Text>
        </View>
      );
    });

  //const today = new Date().toJSON().slice(0, 10);
  //  const .setDate(dateObj.getDate() - 1)
  return (
    <View>
      <Text style={globalStyles.h2}>Analytics</Text>
      <Text style={[globalStyles.h3, globalStyles.centered]}>
        {props.apps.length ? 'Users Today:' : 'No apps found'}
      </Text>
      <Text style={[globalStyles.h1, globalStyles.centered]}>
        {props.apps.length ? parseInt(lastDate) : ''}
      </Text>
      <View style={{ height: 200, padding: 20 }}>
        <BarChart
          style={{ flex: 1 }}
          data={dataGraph}
          gridMin={0}
          svg={{ fill: 'rgb(134, 65, 244)' }}
        />
      </View>
      <View style={[globalStyles.menuWrapper]}>{daysStats}</View>

      <Text style={[globalStyles.h3, globalStyles.left]}>
        {props.apps.length
          ? 'My apps:'
          : 'You need to create an app to see analytics here..'}
      </Text>
      <ScrollView>
        {(props.apps || []).map((app, index) => {
          i++;
          return (
            <TouchableHighlight
              key={'app-link-' + i}
              onPress={() => {
                getAnalyticsData(app, firstDay).then(res => {
                  Object.keys(days).forEach((item, i) => {
                    res[item] = res[item] || 0.1;
                  });
                  res.empty = 0;
                  setDaysState(res);
                });
              }}
              style={{
                backgroundColor: '#eeeeee',
                marginRight: 10,
                padding: 8,
              }}
            >
              <View>
                <Text key={'app' + app.id}>{app.name}</Text>
              </View>
            </TouchableHighlight>
          );
        })}
      </ScrollView>
    </View>
  );
};

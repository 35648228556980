import React from 'react';
import { connect } from 'react-redux';
import { Content } from '../../components/content';
import { processElements } from '../../utils/processors';

const mapElements = contentElems => processElements(contentElems);

const mapStateToProps = state => {
  console.log('STATE..', state);
  return {
    elements: mapElements(state.content.elements || []),
    pagename: state.content.pagename || 'unknown',
    pageId: state.content.pageId,
    splashPageFlag: state.content.splashPageFlag,
    imageBackgroundCover: state.content.imageBackgroundCover,
    app: state.app,
    loadAppsPage: state.loadAppsPage || false,
    splashPage: state.splashPage || false,
    pageAuthentication: (state.content || {}).authentication || false,
    noPadding: state.content.noPadding || false,
    previousPage: state.menuItemSelected.previousPage || false,
    currentPage: state.menuItemSelected.currentPage || false,
    loadingAnimation: state.content.loadingAnimation
  };
};

export default connect(mapStateToProps)(Content);

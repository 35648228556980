import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import YouTube from 'react-native-youtube';
import { getStyle } from '../../styles/container';

export const YouTubeElement = props => {
    const youtubeStyles = getStyle('youtube', props.app);
    return (
        <View>
            <iframe style={youtubeStyles.wrapper} src={"https://www.youtube.com/embed/" + (props.videoId || 'SQIbeAk-bFA')} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        </View>
    );
};

import { firebaseServiceGet, listenFirestoreQuery } from '../services/firebase';

import AsyncStorage from '@react-native-community/async-storage';
import { setContent, loadAppsPage } from '../reducers/actions';
import { contentElementsDefault } from '../elements';

export const loadHomepageContent = async (app, dispatch, ignoreCookie) => {
  const pageInCookie = ignoreCookie ? false : await AsyncStorage.getItem('pageId');

  if (app && app.id) {
    listenFirestoreQuery({
      collection: 'pages',
      docId: pageInCookie || app.pages[0].id,
      callback: (page) => {
        page.id = pageInCookie || app.pages[0].id;
        if (page && page.app === app.id) {
          dispatch(loadAppsPage(false));
          dispatch(setContent(page));
        } else {
          loadHomepageContent(app, dispatch, true);
        }
      }
    });
  }
};

import React from 'react';
import { connect } from 'react-redux';
import { Wrapper } from '../../components/wrapper';

const mapStateToProps = state => {
  console.log('stateAppID', state);
  return {
    app: state.app,
    loading: state.loadAppsPage || false,
    splashPage: state.splashPage || false,
    splashPageFlag: state.content.splashPageFlag || false,
    loadingAnimation: state.content.loadingAnimation || false,
    imageBackgroundCover: state.content.imageBackgroundCover
  };
};

export default connect(mapStateToProps)(Wrapper);

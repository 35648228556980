import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const CalendarComp = (props) => {
    const [value, onChange] = useState(new Date());
    const formatDate = (d) => {
        return (d.getMonth() + 1) +
            "/" + d.getDate() +
            "/" + d.getFullYear();
    }

    useEffect(() => {

        const stylesArr = [
            '.react-calendar.'+props.id+' .react-calendar__navigation {margin-bottom: 0;}',
            '.react-calendar.'+props.id+' .react-calendar__month-view__weekdays {font-size: 0.6em;}'
        ];

        const stylesByType = {
            calendar : [],
            button: [],
            buttonHover: [],
            buttonWeekend: [],
            buttonNeighboringMonth: [],
            buttonToday: [],
            buttonActive: [],

        }
        if(props.backgroundColor) stylesByType.calendar.push('background-color: '+props.backgroundColor);

        if(props.buttonBackgroundColor) stylesByType.button.push('background-color: '+props.buttonBackgroundColor);
        if(props.buttonFontColor) stylesByType.button.push('color: '+props.buttonFontColor);
        if(props.buttonHoverBackgroundColor) stylesByType.buttonHover.push('background-color: '+props.buttonHoverBackgroundColor);
        if(props.buttonHoverFontColor) stylesByType.buttonHover.push('color: '+props.buttonHoverFontColor);

        if(props.buttonWeekendBackgroundColor) stylesByType.buttonWeekend.push('background-color: '+props.buttonWeekendBackgroundColor);
        if(props.buttonWeekendFontColor) stylesByType.buttonWeekend.push('color: '+props.buttonWeekendFontColor);

        if(props.buttonNeighboringMonthBackgroundColor) stylesByType.buttonNeighboringMonth.push('background-color: '+props.buttonNeighboringMonthBackgroundColor);
        if(props.buttonNeighboringMonthFontColor) stylesByType.buttonNeighboringMonth.push('color: '+props.buttonNeighboringMonthFontColor);

        if(props.buttonTodayBackgroundColor) stylesByType.buttonToday.push('background-color: '+props.buttonTodayBackgroundColor);
        if(props.buttonTodayFontColor) stylesByType.buttonToday.push('color: '+props.buttonTodayFontColor);

        if(props.buttonActiveBackgroundColor) stylesByType.buttonActive.push('background-color: '+props.buttonActiveBackgroundColor);
        if(props.buttonActiveFontColor) stylesByType.buttonActive.push('color: '+props.buttonActiveFontColor);

        Object.keys(stylesByType).forEach(styleKey => {
           if(stylesByType[styleKey].length > 0){
                let styleSelector;
                switch (styleKey){
                    case 'calendar': styleSelector = '.react-calendar.'+props.id; break;
                    case 'button': styleSelector = '.react-calendar.'+props.id+' button'; break;
                    case 'buttonHover': styleSelector = '.react-calendar.'+props.id+' button:hover'; break;
                    case 'buttonNeighboringMonth': styleSelector = '.react-calendar.'+props.id+' button.react-calendar__month-view__days__day--neighboringMonth'; break;
                    case 'buttonWeekend': styleSelector = '.react-calendar.'+props.id+' button.react-calendar__month-view__days__day--weekend'; break;
                    case 'buttonToday': styleSelector = '.react-calendar.'+props.id+' button.react-calendar__tile--now'; break;
                    case 'buttonActive': styleSelector = '.react-calendar.'+props.id+' button.react-calendar__tile--active'; break;
                }
                stylesArr.push(styleSelector+' {'+stylesByType[styleKey].join('; ')+'}');
           }
        });

        if(stylesArr.length > 0){
            const $style = document.createElement("style");
            document.head.appendChild($style);
            $style.innerHTML = stylesArr.join("\n");
        }

    });

    const wrapperStyles = { backgroundColor: 'white', fontFamily: 'arial, sans-serif' };
    if(props.paddingTop) wrapperStyles.paddingTop = props.paddingTop;
    if(props.backgroundColor) wrapperStyles.backgroundColor = props.backgroundColor;
    if(props.fontColor) wrapperStyles.color = props.fontColor;

    return (
        <div style={wrapperStyles}>
            <Calendar
                className={props.id}
                onChange={(val) => { onChange(val); }}
                defaultValue={value}
            />
            <h3 style={{ textAlign: 'center' }}>{formatDate(value)}</h3>
        </div>
    );
}
export default CalendarComp;
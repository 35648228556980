import React from 'react';
import DeviceInfo from 'react-native-device-info';
import {
  StyleSheet,
  Platform,
  Button,
  Image,
  Text,
  View,
  Dimensions,
  TouchableHighlight,
  ScrollView,
} from 'react-native';
import { Icon } from 'react-native-elements';
import AsyncStorage from '@react-native-community/async-storage';
import { getStyle } from '../../styles/container';
import {
  setContent,
  setLoading,
  displaySplashPage,
  setMenuItemSelected,
  changeTitle,
} from '../../reducers/actions';
import { isTesterApp, appId, notificationsToggle, isSignInDisabled } from '../../../app-settings';
import { processMenu } from '../../utils/processors';
import { firebaseServiceGet, listenFirestoreQuery } from '../../services/firebase';
import { signOut } from '../../services/auth';

export const MenuOverlay = ({ app, dispatch, user }) => {
  const appItems = ((app || {}).pages || [])
    .filter(page => !(app.hiddenPages || []).includes(page.id)).filter(p => p.position !== 'bottom');
  const globalStyles = getStyle('globalStyles', app);
  const menuOverlayStyle = getStyle('menuOverlay', app);
  const menuStyle = getStyle('menu', app);
  const model = parseInt(DeviceInfo.getModel().replace('iPhone', ''));

  function navigateToPage(page) {
    window.top.postMessage({ type: 'navigate', key: page.id }, '*');
    AsyncStorage.setItem('pageId', page.id);
    dispatch(changeTitle({ title: page.menuTitle }));
    dispatch(setMenuItemSelected(page.orderTopMenu));
    listenFirestoreQuery({
      collection: 'pages',
      docId: page.id,
      callback: (page) => {
        dispatch(setContent(page));
        dispatch(setLoading({ loading: false }));
        dispatch(displaySplashPage(false));
      }
    });
  }
  let i = 0;
  const signInElement = !isSignInDisabled ? (
    <TouchableHighlight
      key={'log-in'}
      onPress={() => {
        console.log('LOGGING IN');
        dispatch(setLoading({ loading: false }));
        dispatch(displaySplashPage('login'));
      }}
      underlayColor={menuOverlayStyle.wrap.backgroundColor}
    >
      <View style={menuOverlayStyle.items}>
        <Icon
          name="subdirectory-arrow-left"
          color={menuOverlayStyle.wrap.color}
        />
        <Text style={menuOverlayStyle.title}>Sign In</Text>

        <View style={menuOverlayStyle.iconRight}>
          <Icon
            name="chevron-right"
            color={menuOverlayStyle.wrap.color}
          />
        </View>
      </View>
    </TouchableHighlight>
  ) : null;
  const topCloseButton = model <= 8 ? 40 : 40;
  const titlePaddingTop = { marginTop: model <= 8 ? -10 : 0, paddingTop: 0 };
  return (
    <View style={[menuOverlayStyle.wrap]}>
      <View
        style={{
          position: 'absolute',
          right: 20,
          padding: 0,
          top: topCloseButton,
          zIndex: 9999,
        }}
      >
        <TouchableHighlight
          key={'close-link'}
          onPress={() => {
            dispatch(displaySplashPage(false));
            dispatch(setLoading({ loading: false }));
          }}
          underlayColor={menuOverlayStyle.wrap.backgroundColor}
        >
          <Icon name="close" color={menuOverlayStyle.wrap.color} />
        </TouchableHighlight>
      </View>
      <Text
        key={'apps-heading'}
        style={[globalStyles.h2, { paddingLeft: 20, color: globalStyles.menuItemText.color }, titlePaddingTop]}
      >
        Menu
      </Text>
      <ScrollView style={{ paddingTop: 20 }}>
        {appItems.map((page, index) => {
          i++;
          return (
            <TouchableHighlight
              key={'page-link-' + i}
              onPress={() => {
                navigateToPage(page);
              }}
              underlayColor={menuOverlayStyle.wrap.backgroundColor}
            >
              <View style={menuOverlayStyle.items}>
                <Icon
                  name={(page.icon || "fa-home").replace("far ", "").replace("fab ", "").replace("fa-", "")}
                  type="font-awesome"
                  color={menuOverlayStyle.wrap.color}
                  style={{ alignSelf: 'flex-start' }}
                />
                <Text key={'page' + page.id} style={menuOverlayStyle.title}>
                  {page.menuTitle}
                </Text>
                <View style={menuOverlayStyle.iconRight}>
                  <Icon
                    name="chevron-right"
                    color={menuOverlayStyle.wrap.color}
                  />
                </View>
              </View>
            </TouchableHighlight>
          );
        })}
        {isTesterApp ? (
          <TouchableHighlight
            key={'myapps-link'}
            onPress={() => {
              dispatch(displaySplashPage('myapps'));
            }}
            underlayColor={menuOverlayStyle.wrap.backgroundColor}
          >
            <View style={menuOverlayStyle.items}>
              <Icon
                name="cogs"
                type="font-awesome"
                color={menuOverlayStyle.wrap.color}
              />
              <Text style={menuOverlayStyle.title}>My Apps</Text>

              <View style={menuOverlayStyle.iconRight}>
                <Icon
                  name="chevron-right"
                  color={menuOverlayStyle.wrap.color}
                />
              </View>
            </View>
          </TouchableHighlight>
        ) : null}
        {notificationsToggle ? (
          <TouchableHighlight
            key={'settings'}
            onPress={() => {
              dispatch(
                setContent({
                  elements: [{ type: 'notificationButtons' }],
                  pagename: 'Notifications'
                })
              );
              dispatch(displaySplashPage(false));
            }}
            underlayColor={menuOverlayStyle.wrap.backgroundColor}
          >
            <View style={menuOverlayStyle.items}>
              <Icon
                name="bell"
                type="font-awesome"
                color={menuOverlayStyle.wrap.color}
              />
              <Text style={menuOverlayStyle.title}>Notifications</Text>

              <View style={menuOverlayStyle.iconRight}>
                <Icon
                  name="chevron-right"
                  color={menuOverlayStyle.wrap.color}
                />
              </View>
            </View>
          </TouchableHighlight>
        ) : null}
        {user ? (
          <TouchableHighlight
            key={'log-out'}
            onPress={() => {
              signOut(dispatch);
              dispatch(displaySplashPage(false));
            }}
            underlayColor={menuOverlayStyle.wrap.backgroundColor}
          >
            <View style={menuOverlayStyle.items}>
              <Icon
                name="subdirectory-arrow-left"
                color={menuOverlayStyle.wrap.color}
              />
              <Text style={menuOverlayStyle.title}>Sign Out</Text>

              <View style={menuOverlayStyle.iconRight}>
                <Icon
                  name="chevron-right"
                  color={menuOverlayStyle.wrap.color}
                />
              </View>
            </View>
          </TouchableHighlight>
        ) : signInElement}
      </ScrollView>
    </View>
  );
};

export const splashPage = {
  wrap: {
    paddingBottom: 40,
    backgroundColor: '#15411b',
    flex: 1,
    flexDirection: 'column',
    paddingTop: 40,
    textAlign: 'center',
    alignItems: 'center',
    color: 'white'
  },
  appItem: {
    borderColor: '#fff',
    borderWidth: 1,
    minWidth: 150,
    borderRadius: 5,
    padding: 5,
    marginTop: 10,
  },
  appText: {
    color: '#fff',
    textAlign: 'center'
  },
};

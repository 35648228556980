export const footer = {
  wrap: {
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    flex: 1,
    flexDirection: 'row',
    maxHeight: 100,
    height: 80,
    justifyContent: 'center',
    padding: 3,
  },
  menuItem: {
    minWidth: 'auto',
    paddingTop: 10,
    margin: 3,
    marginBottom: 8,
    width: 65,
    height: 55,
    borderRadius: 15,
  },
  menuItemText: {
    width: 70,
    height: 55,
    color: '#fff',
    textAlign: 'center'
  },
  noPaddingBottom: {
    maxHeight: 70,
  },
};

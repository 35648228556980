import React, { useEffect, useState } from 'react';
import { View, Image, Text, TouchableHighlight, Linking } from 'react-native';
import { Icon } from 'react-native-elements';
import { globalStyles } from '../../styles/global';

const removeItemFromTable = (c, removeLink) => {
  const index = c.split('-')[1];
  console.log("remove item", removeLink[index]);
  if (removeLink[index]) { removeLink[index](); }

}

/**
 * Expecting to be [ [el1, el2], [ el3, 'remove-item-INDEX'] ]
 * @param {*} props 
 * @returns 
 */
export const TableContent = (props) => {
  const indicatorStyle = {
    alignSelf: 'flex-end',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.11)',
    overflow: "hidden",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
    textAlign: "center"
  }
  const mainColor = props.color || '#ffffff'

  return (
    <View
      style={{

        borderRadius: 15,
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        paddingTop: 5,
        paddingBottom: 5,
        marginTop: 5,
        overflow: "hidden",
        marginBottom: 5
      }}
    >
      <Text style={[props.globalStyles.h2, props.globalStyles.centered, { paddingBottom: 7 }]}>{props.title}</Text>
      {props.data.length > 0 ? null :
        <Text style={[props.globalStyles.h3, props.globalStyles.centered, { paddingBottom: 7 }]}>Nothing found</Text>
      }
      {props.data.map((row, index) => {
        let columnCount = 0;
        const symbol = (row[0][0] || "").toLowerCase();

        const coinIcon = props.icons[symbol] || null;

        const content = (
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              marginVertical: 3,
              marginHorizontal: 12,
              alignSelf: 'stretch',
              padding: 10,
              borderColor: '#555', // if you need 
              borderWidth: 1,
              borderRadius: 10,
              overflow: 'hidden',
              backgroundColor: 'rgba(255, 255, 255, 0.11)'
            }}
          >
            {coinIcon && coinIcon !== null ? <View style={{ width: 35, marginLeft: 5, marginRight: 5 }}>
              {coinIcon !== 'icon' ? <Image source={coinIcon} style={{ width: 30, marginTop: 3, height: 30, position: 'absolute', zIndex: 5 }} /> : null}
              <Icon
                name="btc"
                type="font-awesome"
                color="#999"
                style={{ alignSelf: 'center', marginTop: 5 }}
              />
            </View> : null}
            {row.map((content, rowIndex) => {
              const textAlign = columnCount === 1 ? 'right' : 'left';


              columnCount++;
              if (Array.isArray(content)) {
                return (
                  <View style={{ flex: 1, alignSelf: 'stretch' }} key={'table-row-' + rowIndex}>
                    {content.map((c, index) => {
                      let fieldStyle = { color: mainColor, paddingRight: 5 };
                      if (!isNaN(parseFloat(c))) {
                        fieldStyle = parseFloat(c) < 0 ? { ...indicatorStyle, backgroundColor: '#ad0000b3' } : { ...indicatorStyle, color: 'yellow', backgroundColor: 'rgba(0, 0, 0, 0.2)' };
                      }
                      console.log("remove item", c && c.startsWith && c.startsWith('removeitem'));
                      const removeItem = c && c.startsWith && c.startsWith('removeitem') ? (<TouchableHighlight onPress={() => removeItemFromTable(c, props.removeLink)}><View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}><Text style={[globalStyles.p, { color: '#999', marginTop: 4 }]}>Remove</Text><Icon
                        name="trash"
                        type="font-awesome"
                        color="#999"
                        style={{ alignSelf: 'flex-end', marginLeft: 5 }}
                      /></View></TouchableHighlight>) : null;
                      const fontSize = index === 0 ? { fontSize: 18 } : fieldStyle;

                      return removeItem || (<View key={'table-content-' + Math.random()}><Text
                        key={'table-content-text-' + Math.random()}
                        style={[{ textAlign: textAlign, color: mainColor }, fontSize]}
                      >
                        {c}
                      </Text></View>)
                    })
                    }
                  </View>
                )
              } else {
                return (
                  <View style={{ flex: 1, alignSelf: 'stretch' }}>
                    <Text
                      key={'table-content-' + Math.random()}
                      style={[{ textAlign: textAlign, color: mainColor }]}
                    >
                      {content}
                    </Text>
                  </View>
                )
              }

            })}
          </View>
        );

        if (props.dataLink && props.dataLink[index]) {
          return <TouchableHighlight
            underlayColor="rgba(255, 255, 255, 0.2)"
            key={'row-link-' + index}
            onPress={() => {
              if (props.dataLink[index]) { props.dataLink[index](); }
            }}
          >{content}</TouchableHighlight>;

        } else {
          return content;
        }
      }

      )}
    </View>
  );
};

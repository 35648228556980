export const video = {
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  videoWrapper: { alignSelf: 'stretch', textAlign: 'center', minHeight: 200 },
};

import React, { useEffect, useState } from 'react';
import { Image, View, Text, Dimensions } from 'react-native';
import { getStyle } from '../../styles/container';

export const ImageComponent = props => {
    const globalStyles = getStyle('globalStyles', props.app);
    const t = props;
    const [imageStyle, setImageStyle] = useState({ height: t.height || 130 });

    useEffect(() => {
        Image.getSize(t.src, (width, height) => {

            if (t.width) {
                imageStyle.width = t.width;
            } else {
                imageStyle.width = width;
            }
            if (t.height) {
                imageStyle.height = t.height;
            } else {
                if (t.width !== '100%') {
                    if (t.width) {
                        imageStyle.height = t.width / width * height;
                    } else {
                        imageStyle.height = height;
                    }
                } else {
                    imageStyle.height = ((Dimensions.get('window').width - 50) / width) * height;
                }
            }

            const fixSizeVal = function(val){
                if(typeof val !== "string") return val;
                if(val.match(/^-?\d+$/)) return parseInt(val);
                if(val.match(/^\d+\.\d+$/)) return parseFloat(val);
                return val;
            }
            imageStyle.height = fixSizeVal(imageStyle.height);
            imageStyle.width = fixSizeVal(imageStyle.width);
            if(imageStyle.width === width) imageStyle.width = '100%';
            imageStyle.resizeMode = 'stretch';

            setImageStyle(imageStyle);
        });
    }, []);
    const placeholderStyle = { padding: 5, border: '1px solid #ccc', backgroundColor: '#ccc', height: 160, justifyContent: 'center' }
    return t.src ? <Image
        key={t.key || "image"}
        style={imageStyle}
        source={{ uri: t.src }}
    /> : <View style={placeholderStyle}><Text style={[globalStyles.h3, globalStyles.centered]}>Image</Text></View>


};


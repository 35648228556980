import { combineReducers } from 'redux';

import {
  SET_HEADER,
  SET_FOOTER,
  SET_CONTENT,
  LOAD_APPS,
  SET_APP,
  DISPLAY_SPLASH_PAGE,
  MENU_ITEM_SELECTED,
  CHANGE_TITLE,
  SET_PREVIOUS_PAGE,
  SET_CURRENT_PAGE,
  CHANGE_TITLE_ICON,
  SET_LOADING
} from './actions';

function header(state = [], action) {
  switch (action.type) {
    case SET_HEADER:
      return action.header;
    default:
      return state;
  }
}

function footer(state = [], action) {
  switch (action.type) {
    case SET_FOOTER:
      return action.footer;
    default:
      return state;
  }
}

function content(state = {}, action) {
  switch (action.type) {
    case SET_CONTENT:
      return {
        ...state,
        elements: action.elements,
        pagename: action.pagename,
        pageId: action.pageId,
        authentication: action.authentication,
        splashPageFlag: action.splashPageFlag,
        imageBackgroundCover: action.imageBackgroundCover,
        noPadding: action.noPadding
      };
    case SET_LOADING:
      return { ...state, loadingAnimation: action.loadingAnimation, loadingMessage: action.loadingMessage }
    default:
      return state;
  }
}

function loadAppsPage(state = [], action) {
  switch (action.type) {
    case LOAD_APPS:
      return action.status;
    default:
      return state;
  }
}

function app(state = [], action) {
  switch (action.type) {
    case SET_APP:
      return action.app;
    default:
      return state;
  }
}
function splashPage(state = [], action) {
  switch (action.type) {
    case DISPLAY_SPLASH_PAGE:
      return action.splashPage;
    default:
      return false;
  }
}

function menuItemSelected(state = [], action) {

  switch (action.type) {
    case MENU_ITEM_SELECTED:
      return { ...state, order: action.order };
    case CHANGE_TITLE:
      return { ...state, title: action.title };
    case SET_PREVIOUS_PAGE:
      return { ...state, previousPage: action.previousPage };
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.currentPage };
    case CHANGE_TITLE_ICON:
      return { ...state, titleIcon: action.titleIcon };
    default:
      return state;
  }
}

const reactAppTemp = combineReducers({
  header,
  footer,
  content,
  loadAppsPage,
  app,
  splashPage,
  menuItemSelected
});

export default reactAppTemp;

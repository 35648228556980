import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-community/async-storage";
import { View, Text } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { getStyle } from "../../styles/container";
import { majorCurrencies } from "./utils";

export const SettingsPage = (props) => {
  const globalStyles = getStyle("globalStyles", props.app);

  const [defaultCurrency, setDefaultCurrency] = useState("USD");
  AsyncStorage.getItem("defaultCurrency").then((v) =>
    setDefaultCurrency(v || "USD")
  );
  return (
    <View>
      <Text
        style={[
          globalStyles.h2,
          props.centered === true ? globalStyles.centered : {},
        ]}
      >
        Settings
      </Text>
      <Text
        style={[
          globalStyles.h3,
          props.centered === true ? globalStyles.centered : {},
        ]}
      >
        Default currency:
      </Text>

      <Picker
        selectedValue={defaultCurrency}
        itemStyle={{
          height: 100,
          fontSize: 14,
          padding: 3,
          width: "100%",
          color: globalStyles.p.color,
        }}
        onValueChange={(itemValue, itemIndex) => {
          setDefaultCurrency(itemValue);
          AsyncStorage.setItem("defaultCurrency", itemValue);
        }}
      >
        {majorCurrencies.map((c) => (
          <Picker.Item label={c} value={c} key={"pitem-" + c} />
        ))}
      </Picker>
      {props.showIntroInfo ? (
        <Text
          style={[
            globalStyles.h3,
            props.centered === true ? globalStyles.centered : {},
          ]}
        >
          You can change this setting later in Settings
        </Text>
      ) : null}
    </View>
  );
};

import auth from '@react-native-firebase/auth';
import { GoogleSignin } from '@react-native-community/google-signin';
import { LoginManager, AccessToken } from 'react-native-fbsdk';
import DeviceInfo from 'react-native-device-info';
//import { AppleButton } from '@invertase/react-native-apple-authentication';
import { appleAuth } from '@invertase/react-native-apple-authentication';

import {
  firebaseServiceGet,
  firebaseServiceInsert,
  firebaseServiceGetSingle,
} from '../services/firebase';
import { setApp } from '../reducers/actions';
import { isTesterApp } from '../../app-settings';

export async function onFacebookButtonPress() {
}

export async function onAppleButtonPress() {
}

/*
export async function onAppleButtonPress() {
  // Start the sign-in request
  console.log('AppleAuthRequestOperation', AppleAuthRequestOperation);
  try {
    const appleAuthRequestResponse = await appleAuth.performRequest({
      requestedOperation: appleAuth.Operation.LOGIN,
      requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
    });

    // Ensure Apple returned a user identityToken
    if (!appleAuthRequestResponse.identityToken) {
      throw 'Apple Sign-In failed - no identify token returned';
    }

    // Create a Firebase credential from the response
    const { identityToken, nonce } = appleAuthRequestResponse;
    const appleCredential = auth.AppleAuthProvider.credential(
      identityToken,
      nonce
    );

    // Sign the user in with the credential
    return auth().signInWithCredential(appleCredential);
  } catch (e) {
    console.log('errororororo', e);
  }
}
*/

export async function googleLoginService() {
  GoogleSignin.configure({
    webClientId:
      '365443702640-4lc0idejlse1fv10rh2d0nvvmcfh43k9.apps.googleusercontent.com'
  });
  try {
    const { idToken } = await GoogleSignin.signIn();

    // Create a Google credential with the token
    const googleCredential = auth.GoogleAuthProvider.credential(idToken);

    // Sign-in the user with the credential
    return auth().signInWithCredential(googleCredential);
  } catch (error) {
    console.log('error', error);
    return {};
  }
}

export async function emailPasswordLogin(username, password, redirect) {
  console.log('starting sign in');
  return new Promise((resolve, reject) => {
    let signInFlag = false;
    setTimeout(() => {
      if (!signInFlag) {
        resolve({ status: false, error: 'Sign in timed out, try again..' });
      }
    }, 10000);
    auth()
      .signInWithEmailAndPassword(username, password)
      .then(() => {
        signInFlag = true;
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === 'auth/user-not-found') {
          auth()
            .createUserWithEmailAndPassword(username, password)
            .then(() => {
              console.log('User account created & signed in!');
              redirect();
            })
            .catch(error => {
              if (error.code === 'auth/email-already-in-use') {
                console.log(
                  'That email address is already in use, did you login with social media previously?'
                );
              }

              if (error.code === 'auth/invalid-email') {
                console.log('That email address is invalid!');
              }

              resolve({ status: false, error: error.code });
            });
        } else {
          let errorMessage = error.code;
          if (error.code === 'auth/invalid-email') {
            errorMessage = 'The email or password is false, please try again';
          }
          if (error.code === 'auth/network-request-failed') {
            errorMessage = 'Internet connection failure, please try again';
          }
          resolve({ status: false, error: errorMessage });
        }
      });
  });
}

export async function signOut(dispatch) {
  auth()
    .signOut()
    .then(() => {
      console.log('User signed out!');
      if (isTesterApp) {
        dispatch(setApp(null));
      }
    });
}

function checkIfUserExists(email) {
  return new Promise(async function (resolve, reject) {
    try {
      const user = await firebaseServiceGetSingle({
        collection: 'users',
        docId: email
      });
      console.log('check if user exists', user);
      resolve(user);
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
}

export async function addUserToApp(app, user) {
  return new Promise(async function (resolve) {
    console.log('addUserToApp', app, user);
    try {
      const exists = await checkIfUserExists(app.id, user.email);
      console.log("User exists", exists);
    } catch (err) {
      const obj = user._user;
      obj.app = app.id;
      firebaseServiceInsert({
        collection: 'users',
        obj: obj,
      }).then(user => {
        resolve(user);
      });
    }

  });
}

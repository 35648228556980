import React, { useEffect, useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import * as Progress from 'react-native-progress';

import { getStyle } from '../../styles/container';
import { TableContent } from '../../components/content/tableContent';
import { firebaseServiceGet } from '../../services/firebase';
import AsyncStorage from '@react-native-community/async-storage';
import { SettingsPage } from './settings';
import { Alerts } from './alerts';
import { getPrice, mapLinks, mapIcons, mapAssets } from './utils';
import { setLoading } from '../../reducers/actions';
const majorCurrencies = ["USD", "EUR", "JPY", "GBP", "AUD", "CAD", "CHF"];
import {
    useReachability,

    useInstalled,
} from 'react-native-watch-connectivity';
import BackgroundFetch from "react-native-background-fetch";
import { CoinPageWrap } from './coin-page';
import PortfolioWidget from './portfolio-widget';
import { sendToWatch } from '../watchos';

export const ModuleCryptoCurrency = props => {
    const globalStyles = getStyle('globalStyles', props.app);

    if (props.page === 'settings') {
        return <SettingsPage majorCurrencies={majorCurrencies} globalStyles={globalStyles} />;
    }
    if (props.page === 'alerts') {
        return <Alerts setCryptoRatesInContent={props.setCryptoRatesInContent}
            setCryptoInContent={props.setCryptoInContent}
            majorCurrencies={majorCurrencies} globalStyles={globalStyles} alerts={props.alerts} setAlerts={props.setAlerts} />;
    }
    if (props.page === 'portfolio' || props.page === 'portfolioFull') {

        if (props.previousPage && props.previousPage === props.currentPage) {
            return null;
        }
        return <PortfolioWidget page={props.page} globalStyles={globalStyles} pageId={props.pageId} dispatch={props.dispatch} previousPage={props.previousPage} />;
    }
    const [processedDataAssets, setProcessedDataAssets] = useState([]);
    const [processedDataRates, setProcessedDataRates] = useState([]);
    const [processedData, setProcessedData] = useState([]);
    const [defaultCurrency, setDefaultCurrency] = useState(false);

    const [coinPage, setCoinPage] = useState(false);
    const [dataLink, setDataLink] = useState([]);
    const [coinData, setCoinData] = useState([]);
    const [icons, setIcons] = useState([]);


    const initBackgroundFetch = async () => {
        // BackgroundFetch event handler.
        const onEvent = async (taskId) => {
            console.log('[BackgroundFetch] task: ', taskId);
            // Do your background work...
            await addEvent(taskId);
            // IMPORTANT:  You must signal to the OS that your task is complete.
            BackgroundFetch.finish(taskId);
        }

        // Timeout callback is executed when your Task has exceeded its allowed running-time.
        // You must stop what you're doing immediately BackgorundFetch.finish(taskId)
        const onTimeout = async (taskId) => {
            console.warn('[BackgroundFetch] TIMEOUT task: ', taskId);
            BackgroundFetch.finish(taskId);
        }

        // Initialize BackgroundFetch only once when component mounts.
        if (BackgroundFetch) {
            let status = await BackgroundFetch.configure({ minimumFetchInterval: 15 }, onEvent, onTimeout);
            console.log('[BackgroundFetch] configure status: ', status);
        }

    }

    // Add a BackgroundFetch event to <FlatList>
    const addEvent = (taskId) => {
        // Simulate a possibly long-running asynchronous task with a Promise.
        return new Promise((resolve, reject) => {
            console.log("Refreshing prices and rates");
            checkRates();
            resolve();
        });
    }

    const checkRates = (loadingAnimation) => {

        const coins = ["assets", "rates", "bitcoin", "ethereum", "xrp", "bitcoin-cash", "eos", "stellar", "litecoin", "cardano", "tether", "iota", "tron", "ethereum-classic", "monero", "neo", "dash", "binance-coin"];

        const allPromises = coins.map((coin) => firebaseServiceGet({
            collection: 'crypto',
            docId: ["assets", "rates"].includes(coin) ? coin : coin + 'History',
        }));

        Promise.all(allPromises).then((response) => {
            if (loadingAnimation) {
                props.dispatch(setLoading({ loading: false }));
            }
            const assets = response[0].data;
            const rates = response[1].data.filter((c) => majorCurrencies.includes(c.symbol));
            setProcessedDataAssets(response[0]);
            setProcessedDataRates(rates);
            AsyncStorage.setItem('assets', JSON.stringify(assets));
            AsyncStorage.setItem('assetsMajor', JSON.stringify(assets.filter(asset => ['bitcoin', 'etherium', 'litecoin'].includes(asset.id))));
            AsyncStorage.setItem('rates', JSON.stringify(rates));
            if (!props.page) {
                const processedData = mapAssets(assets, rates, defaultCurrency);
                const icons = mapIcons(processedData);
                const dataLink = mapLinks(processedData, coins.filter(coin => !['assets', 'rates'].includes(coin)), setCoinPage, props.dispatch, props.pageId, icons);
                setDataLink(dataLink);
                sendToWatch(processedData);
                setProcessedData(processedData);
                setIcons(icons);
            }
            if (props.page === 'currencyRates') {
                const processedData = rates.map(cc => [[cc.symbol, cc.currencySymbol], [parseFloat(cc.rateUsd).toFixed(2), 'USD']])
                setProcessedData(processedData);
            }
            setCoinData(response.reduce((acc, coin, index) => {
                const coinName = (coin.id || "").replace("History", "");
                acc[coinName] = coin.data;
                return acc;

            }, {}));

        })
    }


    AsyncStorage.getItem('defaultCurrency').then(v => {
        if (defaultCurrency === v) {
            return;
        }
        setDefaultCurrency(v || 'USD');
    });

    try {


        const installed = useInstalled();
        const reachable = useReachability();

        useEffect(() => {
            if (defaultCurrency !== false) {
                /*if (!(props.app || {}).isCryptoModule) {
                    return;
                }*/
                props.dispatch(setLoading({ loading: 'slideInBottom' }));
                checkRates(true);
                //initBackgroundFetch();
                if (!props.disableInterval) {
                    setInterval(() => {
                        checkRates(false);
                    }, 120000);

                }
            }


            if (!installed || !reachable) {
                console.log(`Watch app not installed (${installed}) or reachable (${reachable})`);
            }
        }, [defaultCurrency, props.page])


    } catch (e) {
        console.log(e);
        setProcessedData([{ symbol: "Error found, are you connected to the internet?" }])
    }
    return (
        <ScrollView onScrollEndDrag={(event) => {
            const currentOffset = event.nativeEvent.contentOffset.y;
            const direction = currentOffset > -1 ? 'down' : 'up';
            if (coinPage === false && direction === 'up' && props.disableInterval) {
                props.dispatch(setLoading({ loading: 'fadeOutLoading' }));
                checkRates();
            }
        }}>

            {processedData.length > 0 && !props.previousPage ? (<TableContent data={processedData} dataLink={dataLink} globalStyles={globalStyles} icons={icons} title={props.page === "currencyRates" ? "Fiat Currencies to USD" : "Coins"} />) : null}
            {coinPage && props.previousPage && props.previousPage === props.currentPage ? <CoinPageWrap currentCurrency={defaultCurrency} rates={processedDataRates} page={coinPage} coinData={coinData} assets={processedDataAssets} /> : null}
        </ScrollView>

    );
};

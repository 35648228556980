import { changeTitle, setPreviousPage, setCurrentPage, changeTitleIcon, setLoading } from '../../reducers/actions';

export const majorCurrencies = ["USD", "EUR", "JPY", "GBP", "AUD", "CAD", "CHF"];
export const getPrice = (cc, rates, currentCurrency, type) => {
    const currencyObj = ((rates || processedDataRates).filter(r => r.symbol === (currentCurrency || defaultCurrency))[0] || {});

    let price;
    if (type !== 'skip-currency-calc') {
        price = ((1 / parseFloat(currencyObj.rateUsd || 1)) * parseFloat(cc.priceUsd));
    }
    if (type && (type === 'number' || type.startsWith('number'))) {
        if (type === 'number-roundup-thousands') {
            price = parseFloat(Math.round(price / 1000) * 1000);
        }
        if (type === 'number-roundup-hundreds') {
            price = parseFloat(Math.round(price / 100) * 100);
        }
        if (type === 'number-roundup-tens') {
            price = parseFloat(Math.round(price / 10) * 10);
        }
        if (type === 'number-roundup-ones') {
            price = parseFloat(Math.round(price / 1) * 1);
        }
        if (type === 'number-roundup-pointones') {
            price = parseFloat(Math.round(price / 0.1) * 0.1);
        }
        price = parseFloat((price).toFixed(2))

    } else {
        price = (currencyObj.currencySymbol || '$') + (price || cc.priceUsd).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return price;
}

export const mapAssets = (assets, rates, currentCurrency) => {
    return assets.map(cc => {
        const price = getPrice(cc, rates, currentCurrency);

        const changed = (parseFloat(cc.changePercent24Hr) > 0 ? '+' + parseFloat(cc.changePercent24Hr).toFixed(2) : parseFloat(cc.changePercent24Hr).toFixed(2)) + '%';
        return [[cc.symbol, cc.name], [price, changed]]
    });
}

export const mapLinks = (processedData, coins, setCoinPage, dispatch, currentPage, icons) => {
    const allCoins = [...coins];
    return processedData.map((data) => () => {

        const coinName = data[0][1].toLowerCase().replace(' ', '-');
        const coinSymbol = data[0][0].toLowerCase();
        const index = allCoins.indexOf(coinName);
        const coin = index !== -1 ? allCoins[index] : coinName;


        dispatch(setLoading({ loading: 'fadeOutLoading' }));

        setTimeout(() => {

            setCoinPage(coin);
            console.log("icons[coinName]", icons[coinName]);
            dispatch(changeTitle({ title: data[0][1] }));
            dispatch(changeTitleIcon({ icon: icons[coinName] }));
            dispatch(setPreviousPage({ previousPage: currentPage }));
            dispatch(setCurrentPage({ currentPage: currentPage }));


            dispatch(setLoading({ loading: "slideInLeft" }));
            setTimeout(() => {
                dispatch(setLoading({ loading: false }));
            }, 1500);
        }, 300);

    });
}
export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];
export const helperGetDate = (date) => {
    try {
        let dateTime, formattedTime, month, day;

        dateTime = date;
        console.log("dateTime", date);
        month = months[dateTime.getMonth()];
        day =
            dateTime.getDate() < 10 ? "0" + dateTime.getDate() : dateTime.getDate();
        formattedTime =
            dateTime > 0
                ? day + " " + month + " " + dateTime.getFullYear()
                : "not found";
        return formattedTime;
    } catch (e) {
        console.log(e);
    }
}

export const mapIcons = (processedData) => {
    const iconsObject = {
        btc: require("./images/crypto-icons/btc.png"),
        eth: require("./images/crypto-icons/eth.png"),
        bnb: require("./images/crypto-icons/bnb.png"),
        usdt: require("./images/crypto-icons/usdt.png"),
        xrp: require("./images/crypto-icons/xrp.png"),
        ada: require("./images/crypto-icons/ada.png"),
        doge: require("./images/crypto-icons/doge.png"),
        dot: require("./images/crypto-icons/dot.png"),
        uni: require("./images/crypto-icons/uni.png"),
        ltc: require("./images/crypto-icons/ltc.png"),
        bch: require("./images/crypto-icons/bch.png"),
        link: require("./images/crypto-icons/link.png"),
        usdc: require("./images/crypto-icons/usdc.png"),
        xlm: require("./images/crypto-icons/xlm.png"),
        theta: require("./images/crypto-icons/theta.png"),
        fil: require("./images/crypto-icons/fil.png"),
        wbtc: require("./images/crypto-icons/wbtc.png"),
        xmr: require("./images/crypto-icons/xmr.png"),
        neo: require("./images/crypto-icons/neo.png"),
        eos: require("./images/crypto-icons/eos.png"),
        miota: require("./images/crypto-icons/miota.png"),
        atom: require("./images/crypto-icons/atom.png"),
        btt: require("./images/crypto-icons/btt.png"),
        bsv: require("./images/crypto-icons/bsv.png"),

        mkr: require("./images/crypto-icons/mkr.png"),
        dai: require("./images/crypto-icons/dai.png"),
        xtz: require("./images/crypto-icons/xtz.png"),
        algo: require("./images/crypto-icons/algo.png"),
        ht: require("./images/crypto-icons/ht.png"),
        comp: require("./images/crypto-icons/comp.png"),
        dash: require("./images/crypto-icons/dash.png"),
        xem: require("./images/crypto-icons/xem.png"),
        zec: require("./images/crypto-icons/zec.png"),
        dcr: require("./images/crypto-icons/dcr.png"),

        matic: require("./images/crypto-icons/matic.png"),
        mana: require("./images/crypto-icons/mana.png"),
        enj: require("./images/crypto-icons/enj.png"),
        zil: require("./images/crypto-icons/zil.png"),
        dgb: require("./images/crypto-icons/dgb.png"),
        bat: require("./images/crypto-icons/bat.png"),
        sc: require("./images/crypto-icons/sc.png"),
        yfi: require("./images/crypto-icons/yfi.png"),
        waves: require("./images/crypto-icons/waves.png"),
        stx: require("./images/crypto-icons/stx.png"),

        uma: require("./images/crypto-icons/uma.png"),
        qtum: require("./images/crypto-icons/qtum.png"),
        zen: require("./images/crypto-icons/zen.png"),

        rvn: require("./images/crypto-icons/rvn.png"),
        ont: require("./images/crypto-icons/ont.png"),

        zrx: require("./images/crypto-icons/zrx.png"),
        icx: require("./images/crypto-icons/icx.png"),

        bnt: require("./images/crypto-icons/bnt.png"),
    }
    const icons = processedData.reduce((acc, row) => {
        const coinName = (row[0][0]).toLowerCase();
        acc[coinName] = iconsObject[coinName] || "icon";
        return acc;
    }, {});

    return icons;

}
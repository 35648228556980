import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableHighlight,
  Image,
} from 'react-native';
import { AppleButton } from '@invertase/react-native-apple-authentication';

import auth from '@react-native-firebase/auth';
import { Button, Input } from 'react-native-elements';
import Icon from 'react-native-vector-icons/FontAwesome';
import { getStyle } from '../../styles/container';
import { firebaseServiceGet } from '../../services/firebase';
import { isTesterApp } from '../../../app-settings';
import { displaySplashPage } from '../../reducers/actions';
import {
  googleLoginService,
  onAppleButtonPress,
  emailPasswordLogin,
  signOut,
  onFacebookButtonPress,
} from '../../services/auth';

/*
<View style={{ width: 300, alignItems: 'center', marginTop: 15 }}>
  <Input
    placeholder="Email"
    leftIcon={{ type: 'font-awesome', name: 'envelope' }}
    leftIconContainerStyle={{ opacity: 0.3, marginRight: 10 }}
    onChangeText={val => setEmail(val)}
  />
  <Input
    placeholder="Password"
    leftIcon={{ type: 'font-awesome', name: 'lock' }}
    secureTextEntry={true}
    leftIconContainerStyle={{
      opacity: 0.3,
      marginRight: 10,
      width: 25,
    }}
    onChangeText={val => setPassword(val)}
  />
  <Button
    buttonStyle={[
      globalStyles.marginTop,
      loginPageStyle.genericButton,
      { backgroundColor: '#3c558f' },
    ]}
    type="solid"
    title="Sign in with email"
    onPress={() => emailPasswordLogin(email, password)}
  />
</View>

*/

function processError(e) {
  alert('error' + e.code);
  let error = 'An unknown error has occured, please try again';
  if (e.code.indexOf('account-exists-with-different-credential') !== -1) {
    error =
      'You have previously signed in with this email from another provider. Please use the original provider to log in.';
  }
  alert(error);
}
export const LoginPage = ({ app, dispatch }) => {
  const globalStyles = getStyle('globalStyles', app);
  const loginPageStyle = getStyle('loginPage', app);
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [password, setPassword] = useState();
  const [signInAnimation, setSignInAnimation] = useState(false);
  const plan = (app || {}).plan;
  const socialMediaFlag =
    (app && app.plan === 'Premium') || isTesterApp ? {} : { display: 'none' };
  const logo =
    app.logo ||
    'https://storage.googleapis.com/app-builder-56f84.appspot.com/logo.jpg';
  Image.prefetch(logo);
  const loadingGif =
    'https://storage.googleapis.com/app-builder-56f84.appspot.com/loading.gif';
  Image.prefetch(loadingGif);
  //dispatch(displaySplashPage('myapps'));
  return (
    <View
      style={[
        loginPageStyle.wrapper,
        { alignItems: 'center', backgroundColor: '#ffffff' },
      ]}
    >
      <View
        style={[
          globalStyles.centered,
          signInAnimation ? {} : { display: 'none' },
        ]}
      >
        <Text
          style={[
            globalStyles.h2,
            globalStyles.centered,
            { marginTop: 100, color: '#333333', marginBottom: -100, zIndex: 9 },
          ]}
        >
          Signing in..
        </Text>
        <Image
          fadeDuration={0}
          source={{ uri: loadingGif }}
          resizeMode="contain"
          style={{ width: 300, height: 300 }}
        />
      </View>
      <ScrollView
        keyboardShouldPersistTaps={'handled'}
        style={signInAnimation ? { display: 'none' } : {}}
      >
        <View style={globalStyles.centered}>
          <Image
            resizeMode="contain"
            source={{
              uri: logo,
            }}
            fadeDuration={0}
            style={{ width: 175, height: 60, marginBottom: 10 }}
          />
          <Text
            style={[
              globalStyles.h3,
              globalStyles.centered,
              { marginBottom: 20, color: '#333333' },
            ]}
          >
            Sign in to view this page
          </Text>
        </View>
        <View style={{ width: 300, alignItems: 'center', marginTop: 15 }}>
          {error ? (
            <Text
              style={[
                globalStyles.h4,
                globalStyles.centered,
                {
                  backgroundColor: 'darkred',
                  padding: 10,
                  color: 'white',
                  margin: 0,
                },
              ]}
            >
              Error: {error}
            </Text>
          ) : null}
          <Input
            placeholder="Email"
            leftIcon={{ type: 'font-awesome', name: 'envelope' }}
            leftIconContainerStyle={{ opacity: 0.3, marginRight: 10 }}
            onChangeText={val => setEmail(val)}
          />
          <Input
            placeholder="Password"
            leftIcon={{ type: 'font-awesome', name: 'lock' }}
            secureTextEntry={true}
            leftIconContainerStyle={{
              opacity: 0.3,
              marginRight: 10,
              width: 25,
            }}
            onChangeText={val => setPassword(val)}
          />
          <Button
            buttonStyle={[
              globalStyles.marginTop,
              loginPageStyle.genericButton,
              { backgroundColor: '#3c558f' },
            ]}
            type="solid"
            title="Sign in with email"
            onPress={() => {
              setSignInAnimation(true);
              emailPasswordLogin(email, password).then(success => {
                setSignInAnimation(false);
                if (success.status === false) {
                  setError(success.error);
                }
              });
            }}
          />
        </View>
        <View style={[socialMediaFlag, globalStyles.centered]}>
          <Text
            style={[
              globalStyles.h3,
              globalStyles.centered,
              { marginTop: 20, color: '#333333' },
            ]}
          >
            Other ways:
          </Text>
          <Button
            buttonStyle={[
              globalStyles.marginTop,
              loginPageStyle.genericButton,
              {
                backgroundColor: '#000'
              },
            ]}
            type="solid"
            title="Sign in with Apple"
            icon={
              <Icon
                name="apple"
                size={18}
                color="white"
                style={{ marginRight: 10 }}
              />
            }
            onPress={() =>
              onAppleButtonPress()
                .then(() => console.log('Apple sign-in complete!'))
                .catch(e => processError(e))
            }
          />
          <Button
            buttonStyle={[
              globalStyles.marginTop,
              loginPageStyle.genericButton,
              {
                backgroundColor: '#ea4236'
              },
            ]}
            type="solid"
            title="Sign in with Google"
            icon={
              <Icon
                name="google"
                size={18}
                color="white"
                style={{ marginRight: 10 }}
              />
            }
            onPress={() =>
              googleLoginService()
                .then(user => console.log('Signed in with Google!', user))
                .catch(e => processError(e))
            }
          />
          <Button
            buttonStyle={[
              globalStyles.marginTop,
              loginPageStyle.genericButton,
              {
                backgroundColor: '#3c558f'
              },
            ]}
            type="solid"
            title="Sign in with Facebook"
            icon={
              <Icon
                name="facebook"
                size={18}
                color="white"
                style={{ marginRight: 10 }}
              />
            }
            onPress={() =>
              onFacebookButtonPress()
                .then(() => console.log('Signed in with Facebook!'))
                .catch(e => processError(e))
            }
          />
        </View>
        <Text style={[globalStyles.marginTop, globalStyles.centered]}>
          Provided by UX Builder Easy Login
        </Text>
      </ScrollView>
    </View>
  );
};

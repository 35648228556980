import React, { useState, useEffect, useRef } from 'react';
import { View, Animated, Easing, Dimensions, Text, ImageBackground, TouchableHighlight } from 'react-native';
import { WebView } from 'react-native-webview';

import AsyncStorage from '@react-native-community/async-storage';
import { getStyle } from '../../styles/container';
import { firebaseServiceGet } from '../../services/firebase';
import {
  addUserToApp,
} from '../../services/auth';

import { loadAppsPage, displaySplashPage, setLoading } from '../../reducers/actions';
import { Intro } from './intro';
import Header from '../../containers/header';
import Content from '../../containers/content';
import Loading from '../../containers/content/loading';
import Footer from '../../containers/footer';
import LoginPage from '../../containers/login-page';
import MenuOverlay from '../../containers/splash-page/menu-overlay';
import { isTesterApp, authentication, isWeb } from '../../../app-settings';

const auth = () => {
  return {
    onAuthStateChanged: () => { }
  }
}

export const Wrapper = ({ loading, app, splashPage, splashPageFlag, dispatch, loadingAnimation, imageBackgroundCover }) => {
  const globalStyles = getStyle('globalStyles', app);
  const [initializing, setInitializing] = useState(false);
  const [user, setUser] = useState();
  const [apps, setApps] = useState([]);
  const [intro, setIntro] = useState(false);
  const slideInLeft = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const slideInLeftMenu = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
  const slideInBottom = useRef(new Animated.Value(0)).current;
  const fadeOutLoading = useRef(new Animated.Value(1)).current  // Initial value for opacity: 0
  const width = 414;
  const height = 736;

  useEffect(() => {
    AsyncStorage.getItem('intro').then(intro => setIntro(JSON.parse(intro || `{"step":1}`)));
  }, []);

  // Handle user state changes
  function onAuthStateChanged(u) {
    if (u === null) {
      setUser(null);
    }
    if (!user && u && u.uid) {
      setUser(u);
      addUserToApp(app, u).then(() => {
        dispatch(loadAppsPage(false));
      });
      // setInitializing(true);
      firebaseServiceGet({
        collection: 'apps',
        where: ['author', 'array-contains', u.uid],
      }).then(function (apps) {
        setApps(apps);
        setInitializing(false);
      });
    }
  }


  useEffect(() => {

    const subscriber = auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, [onAuthStateChanged]);


  if (initializing) {
    return null;
  }
  if (!user && authentication) {
    //return <LoginPage />;
  } else {
    /*  if (splashPage === 'myapps' && isTesterApp) {
      return <SplashPage apps={apps} />;
    }
    */
    if (app.mirror) {
      if (isWeb) {
        return <iframe src={app.mirror} frameBorder="0" style={{ width: '100%', height: '100%' }}></iframe>
      }
      return <WebView
        scalesPageToFit={true}
        bounces={false}
        javaScriptEnabled
        source={{
          uri: app.mirror
        }}
        automaticallyAdjustContentInsets={false}
      />
    }
    const fadeOutAnimationList = ['fadeOutWithText', 'fadeOutLoading', 'fadeOutContentPartly'];
    const isFadeOut = (Array.isArray(loadingAnimation) && loadingAnimation.filter(anim => fadeOutAnimationList.indexOf(anim) > -1).length > 0)
    if (loadingAnimation) {

      if (loadingAnimation === 'fadeOutLoading' || isFadeOut) {

        Animated.timing(
          fadeOutLoading,
          {
            toValue: loadingAnimation.includes('fadeOutContentPartly') ? 0.5 : 0,
            duration: 300,
            useNativeDriver: true
          }
        ).start();

      }

      if (loadingAnimation === 'slideInLeft') {

        Animated.timing(
          slideInLeft,
          {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
            easing: Easing.cubic
          }
        ).start();
      }

      if (loadingAnimation.includes('slideInLeftMenu')) {

        Animated.timing(
          slideInLeftMenu,
          {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
            easing: Easing.cubic
          }
        ).start();
      }
      if (loadingAnimation === 'slideInBottom') {

        Animated.timing(
          slideInBottom,
          {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
            easing: Easing.cubic
          }
        ).start();


      }
    }

    const animViewStyle = {
      opacity: loadingAnimation && isFadeOut ? fadeOutLoading : 1
    }
    if (loadingAnimation && loadingAnimation === 'slideInLeft') {
      animViewStyle.transform = [
        {
          translateX: slideInLeft.interpolate({
            inputRange: [0, 1],
            outputRange: [600, 0]
          })
        }
      ];
    }
    if (loadingAnimation === 'slideInBottom') {
      animViewStyle.transform = [
        {
          translateY: slideInBottom.interpolate({
            inputRange: [0, 1],
            outputRange: [600, 0]
          })
        }
      ];
    }
    const animViewStyleMenu = {};
    if (loadingAnimation && loadingAnimation.includes('slideInLeftMenu')) {
      animViewStyleMenu.transform = [
        {
          translateX: slideInLeftMenu.interpolate({
            inputRange: [0, 1],
            outputRange: [600, 0]
          })
        }
      ];
    }
    const imageSource = { uri: imageBackgroundCover };
    const deviceWidth = Dimensions.get('window').width;
    const headerType = (app.headerType || 'simple').replace('-', '');
    const paddingTop = splashPageFlag ? 30 : (headerType === 'multipletitles' ? 99 : 75);
    if (app && app.id) {
      if ((!intro || intro.step !== 'done') && !isWeb) {
        return <View style={{ width, height }}><View style={[globalStyles.mainContent, { padding: 0 }]}><Animated.View
          style={[animViewStyle, {
            flex: 1,
            resizeMode: "strech",
            justifyContent: "center"
          }]}
        >
          <Loading /><Intro intro={intro} globalStyles={globalStyles} dispatch={dispatch} setIntro={setIntro} /></Animated.View></View>
        </View>
      }
      const contentStyle = {
        resizeMode: "strech",
        justifyContent: "center",
        paddingTop,
        overflow: "scroll"
      };
      if(app.style && app.style.colours && app.style.colours['content-bg']){
        contentStyle.backgroundColor = app.style.colours['content-bg'];
      }

      contentStyle.height = app.footerType === 'hide' ? 639 : 577;
      contentStyle.paddingBottom = 30;
      return (<View style={{ width, height, maxWidth: '100%', maxHeight: '100%', position: 'relative' }}>
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {!splashPageFlag ? <Header user={user} /> : null}
          <Loading />
          {splashPage === 'login' ? <LoginPage apps={apps} /> : null}
          {splashPage === 'menu' ? <Animated.View
            style={[animViewStyleMenu, { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 3, }]}
          ><TouchableHighlight onPress={() => { dispatch(displaySplashPage(false)); dispatch(setLoading({ loading: false })) }} style={{ width: '100%', height: '100%' }}><Text></Text></TouchableHighlight></Animated.View> : null}
          {splashPage === 'menu' ? <Animated.View
            style={[animViewStyleMenu, globalStyles.buttonShadow, { shadowOffset: { width: 2, height: 0 }, paddingTop: 20, position: 'absolute', top: 0, left: 50, width: deviceWidth - 50, height: '100%', zIndex: 9999999, }]}
          ><MenuOverlay user={user} /></Animated.View> : null}

          <ImageBackground
            source={imageSource}
            style={contentStyle}
          >
            <View style={[globalStyles.mainContent, { padding: 0, backgroundColor: 'transparent' }]}>
              <Animated.View
                style={[animViewStyle, {
                  flex: 1,
                  resizeMode: "strech",
                  justifyContent: "center"
                }]}
              ><Content user={user} apps={apps} />
              </Animated.View></View></ImageBackground>
          {!splashPageFlag ? <Footer user={user} /> : null}
        </View>
      </View>
      );
    }
  }
  return <View style={{ width, height }}><Loading /></View>;
};
export default Wrapper;

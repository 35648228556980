import React, { useState } from 'react';
import { View, Text, TouchableHighlight, Linking, Platform } from 'react-native';
import { getStyle } from '../../styles/container';

export const DownloadFile = props => {
    const globalStyles = getStyle('globalStyles', props.app);

    return (
        <TouchableHighlight
            key="download-link"
            onPress={() => {
                if (Platform.OS == 'web') {
                    window.open(props.src, '_blank');
                } else {
                    Linking.openURL(props.src);
                }
            }}
            underlayColor={globalStyles.mainContent.backgroundColor}
        >
            <View>
                <Text style={[globalStyles.p, globalStyles.link]}>
                    Download file: {props.content || 'file'}
                </Text>
            </View>
        </TouchableHighlight>
    );
};

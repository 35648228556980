
import { connect } from 'react-redux';
import { Loading } from '../../components/content/loading';

const mapStateToProps = state => {
    console.log("LOADINGMAP", state.content.loadingAnimation);
    return {
        loadingAnimation: (state.content || {}).loadingAnimation,
        loadingMessage: (state.content || {}).loadingMessage,
        app: state.app || {}
    };
};

export default connect(mapStateToProps)(Loading);

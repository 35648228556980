import React from 'react';
import { connect } from 'react-redux';
import { LoginPage } from '../../components/login-page';

const mapStateToProps = state => {
  console.log('state', state);
  return {
    pagename: state.content.pagename || 'unknown',
    app: state.app,
  };
};

export default connect(mapStateToProps)(LoginPage);

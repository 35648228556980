import React, { useState } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { Button } from 'react-native-elements';
import Icon from 'react-native-vector-icons/FontAwesome';
import TrackPlayer, { useProgress } from 'react-native-track-player';

import { getStyle } from '../../styles/container';

async function eventHandler(data) {
  switch (data.type) {
    // Forward remote events to the player
    case 'remote-play':
      TrackPlayer.play();
      break;
    case 'remote-pause':
      TrackPlayer.pause();
      break;
    case 'remote-stop':
      TrackPlayer.stop();
      break;
    case 'remote-next':
      TrackPlayer.skipToNext();
      break;
    case 'remote-previous':
      TrackPlayer.skipToPrevious();
      break;
    case 'remote-seek':
      TrackPlayer.seekTo(data.position);
      break;

    // You can make ducking smoother by adding a fade in/out
    case 'remote-duck':
      TrackPlayer.setVolume(data.ducking ? 0.5 : 1);
      break;

    // Playback updates
    case 'playback-state':
      console.log(data.state);
      break;
    case 'playback-track-changed':
      console.log(data.nextTrack);
      break;
    case 'playback-error':
      Alert.alert('An error ocurred', data.error);
      break;
  }
}
export const addNewTrack = async (key, url, title, track, artist, artwork) => {
  /*if (url) {
    await TrackPlayer.add({
      id: key,
      url: url,
      title: title || 'Unknown title',
      artist: artist || 'Unknown artist',
      artwork: artwork,
    });
  }*/
};

export const MusicElement = props => {
  const allArtwork = props.allArtwork;
  const allTitles = props.allTitles;
  const allArtists = props.allArtists;
  const musicStyles = getStyle('music', props.app);
  if (props.trackCount > 1) {
    console.log("props.artwork");
    addNewTrack(props.key, props.url, props.title, props.track, props.artist, props.artwork);
    return props.isEditable ? <Text style={[musicStyles.title, { position: 'relative' }]}>
      {props.title} by {props.artist}
    </Text> : null;
  }
  const { position, bufferedPosition, duration } = useProgress();
  const [artwork, setArtwork] = useState(props.artwork);
  const [title, setTitle] = useState(props.title);
  const [artist, setArtist] = useState(props.artist);
  const [currentTrack, setCurrentTrack] = useState(0);


  let track = { ...props };

  const start = async () => {
    // Set up the player
    await TrackPlayer.setupPlayer();
    TrackPlayer.updateOptions({
      capabilities: [
        TrackPlayer.CAPABILITY_PLAY,
        TrackPlayer.CAPABILITY_PAUSE,
        TrackPlayer.CAPABILITY_SEEK_TO,
        TrackPlayer.CAPABILITY_SKIP_TO_NEXT,
        TrackPlayer.CAPABILITY_SKIP_TO_PREVIOUS,
      ],
    });
    addNewTrack(
      props.key,
      props.url,
      props.title,
      props.track,
      props.artist,
      props.artwork
    );
  };
  //start();
  const flex = 0.2;
  let indicatorWidth = (position && duration) ? (position / duration * 100) + '%' : '0%';
  //let indicatorFlex = (position && duration) ? (position / duration) : 1;
  const buttonStyle = {
    display: 'inline-block',
    width: '25%'
  };

  return (
    <View>
      <Text>Please, use new component for {title} by {artist}</Text>
      {/*<Text style={[musicStyles.title]}>
        {title} by {artist}
      </Text>
      <Image source={{ uri: artwork }} style={{ width: '100%', height: '350px' }} />
      <View style={[musicStyles.indicatorsWrap]}>
        <Text style={[musicStyles.indicators]}>
          {position}
        </Text>
        <Text style={[musicStyles.indicators]}>{duration}</Text>
      </View>
      <View
        style={musicStyles.indicatorBg}
      >
        <View style={{ width: indicatorWidth, background: '#336699', minHeight: 10 }} />
      </View>
      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
        <Button
          containerStyle={buttonStyle}
          type="solid"
          icon={
            <Icon
              name="backward"
              size={18}
              color="white"
              style={{ marginRight: 10 }}
            />
          }
          onPress={() => {

            const ct = currentTrack - 1;
            if (ct >= 0) {
              TrackPlayer.skipToPrevious();

              setCurrentTrack(ct);
              setArtwork(allArtwork[ct])
              setArtist(allArtists[ct])
              setTitle(allTitles[ct])
            }
          }}
        />
        <Button
          containerStyle={buttonStyle}
          type="solid"
          icon={
            <Icon
              name="play"
              size={18}
              color="white"
              style={{ marginRight: 10 }}
            />
          }
          onPress={() => TrackPlayer.play()}
        />
        <Button
          containerStyle={buttonStyle}
          type="solid"
          icon={
            <Icon
              name="stop"
              size={18}
              color="white"
              style={{ marginRight: 10 }}
            />
          }
          onPress={() => TrackPlayer.pause()}
        />
        <Button
          containerStyle={buttonStyle}
          type="solid"
          icon={
            <Icon
              name="forward"
              size={18}
              color="white"
              style={{ marginRight: 10 }}
            />
          }
          onPress={async () => {
            const ct = currentTrack + 1;
            if (ct < allTitles.length) {
              TrackPlayer.skipToNext();
              setCurrentTrack(ct);
              setArtwork(allArtwork[ct])
              setArtist(allArtists[ct])
              setTitle(allTitles[ct])
            }
          }}
        /></View>*/}
    </View >
  );
};

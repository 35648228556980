import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  Platform,
  Image,
  Text,
  View,
  ScrollView,
} from 'react-native';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import AsyncStorage from '@react-native-community/async-storage';
//import PushNotificationIOS from '@react-native-community/push-notification-ios';
import reactAppTemp from './src/reducers';
import { contentElementsDefault } from './src/elements'
import {
  setApp,
  setContent,
  setLoading,
  setPreviousPage,
  setCurrentPage,
  changeTitle,
  changeTitleIcon
} from './src/reducers/actions';

import { firebaseServiceGet, listenFirestoreQuery } from './src/services/firebase';
import { isTesterApp, appId } from './app-settings.web';
import Wrapper from './src/containers/wrapper';
const store = createStore(reactAppTemp, applyMiddleware(thunkMiddleware));

//get app
if (!isTesterApp && appId && appId !== 'null' && appId !== 'blank') {
  store.dispatch(setLoading({ loading: 'fadeOutLoading' }));
  listenFirestoreQuery({
    collection: 'apps',
    docId: appId,
    callback: (app) => {
      app.id = appId;
      store.dispatch(setLoading({ loading: false }));
      store.dispatch(setApp(app));
    }
  });
}
if (appId === 'blank' || appId === 'null') {
  store.dispatch(setApp({ id: 'blank', elements: contentElementsDefault, pages: [] }));
  store.dispatch(setContent({ elements: contentElementsDefault }));

}
window.onmessage = function (e) {

  if (e.data.type === 'change-page') {
    AsyncStorage.setItem('pageId', e.data.pageId);
    listenFirestoreQuery({
      collection: 'pages',
      docId: e.data.pageId,
      callback: (page) => {

        store.dispatch(setContent(page));
        store.dispatch(setPreviousPage({ previousPage: false }));
        store.dispatch(setCurrentPage({ currentPage: false }));
        store.dispatch(changeTitle({ title: false }));
        store.dispatch(changeTitleIcon({ titleIcon: false }));
        store.dispatch(setLoading({ loading: false }));
      }
    });
  }
}

//console.log("firestore",firestore);
export const App = () => {

  return (
    <Provider store={store}>
      <Wrapper />
    </Provider>
  );
};
export default App;

export const SET_HEADER = 'SET_HEADER';
export const SHOW_MENU = 'SHOW_MENU';
export const SET_FOOTER = 'SET_FOOTER';
export const SET_CONTENT = 'SET_CONTENT';
export const LOAD_APPS = 'LOAD_APPS';
export const SET_APP = 'SET_APP';
export const DISPLAY_SPLASH_PAGE = 'DISPLAY_SPLASH_PAGE';
export const MENU_ITEM_SELECTED = 'MENU_ITEM_SELECTED';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const CHANGE_TITLE_ICON = 'CHANGE_TITLE_ICON';
export const SET_PREVIOUS_PAGE = 'SET_PREVIOUS_PAGE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_LOADING = 'SET_LOADING';

export function loadAppsPage(status) {
  return {
    type: 'LOAD_APPS',
    status
  };
}
export function showMenu(pressStatus) {
  return {
    type: 'SHOW_MENU',
    pressStatus
  };
}
export function setFooter(footer) {
  return {
    type: 'SET_FOOTER',
    footer
  };
}
export function setContent(page) {
  return {
    type: 'SET_CONTENT',
    elements: page.elements,
    pagename: page.name,
    pageId: page.id,
    authentication: page.authentication,
    splashPageFlag: page.splashPageFlag,
    imageBackgroundCover: page.imageBackgroundCover,
    noPadding: page.noPadding
  };
}
export function setApp(app) {
  console.log('set app in actions', app);
  return {
    type: 'SET_APP',
    app
  };
}
export function setHeader(header) {
  return {
    type: 'SET_HEADER',
    header
  };
}

export function displaySplashPage(page) {
  console.log('display splash page', page);
  return {
    type: 'DISPLAY_SPLASH_PAGE',
    splashPage: page || false,
  };
}

export function setMenuItemSelected(obj) {
  return {
    type: 'MENU_ITEM_SELECTED',
    order: obj.order || 1,
  };
}
export function changeTitle(obj) {
  return {
    type: 'CHANGE_TITLE',
    title: obj.title || "",
  };
}

export function setPreviousPage(obj) {
  return {
    type: 'SET_PREVIOUS_PAGE',
    previousPage: obj.previousPage || false,
  };
}
export function setCurrentPage(obj) {
  return {
    type: 'SET_CURRENT_PAGE',
    currentPage: obj.currentPage || false,
  };
}

export function changeTitleIcon(obj) {
  return {
    type: 'CHANGE_TITLE_ICON',
    titleIcon: obj.icon || false,
  };
}

export function setLoading(obj) {

  return {
    type: 'SET_LOADING',
    loadingAnimation: obj.loading || false,
    loadingMessage: obj.message || false,
  };
}

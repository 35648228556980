export const music = {
    title: {
        fontSize: 18,
        display: 'block',
        backgroundColor: 'rgba(0,0,0,0.8)',
        color: 'white',
        padding: 10,
        position: 'absolute',
        top: 10,
        zIndex: 99
    },
    indicatorBg: {
        alignSelf: 'stretch',
        backgroundColor: '#ccc',
        flexDirection: 'row',
        height: 10,
        minHeight: 10,
    },
    indicatorsWrap: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        flex: 1,
        position: 'absolute',
        bottom: 30,
        width: '100%',
        zIndex: 99,
        height: 40,
        padding: 10,
        display: 'none'
    },
    indicators: { display: 'inline', color: 'white', fontSize: 14 }
};

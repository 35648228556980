import React, { useState } from 'react';
import TrackPlayer, { useProgress } from 'react-native-track-player';
import { View, Text, Image, StyleSheet } from 'react-native';
import { Button } from 'react-native-elements';
import Icon from 'react-native-vector-icons/FontAwesome';

import { getStyle } from '../../styles/container';

export const PlaylistElement = props => {

    const musicStyles = getStyle('music', props.app);

    const { position, duration } = useProgress();

    const firstTrack = props.playlist[0]||{
        id: 0,
        url: '',
        title: 'Unknown title',
        artist: 'Unknown artist',
        cover: ''
    }

    const [currentTrack, setCurrentTrack] = useState(firstTrack);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);


    const start = async () => {
        await TrackPlayer.setupPlayer();
        await TrackPlayer.updateOptions({
            capabilities: [
                TrackPlayer.CAPABILITY_PLAY,
                TrackPlayer.CAPABILITY_PAUSE,
                TrackPlayer.CAPABILITY_SEEK_TO,
                TrackPlayer.CAPABILITY_SKIP_TO_NEXT,
                TrackPlayer.CAPABILITY_SKIP_TO_PREVIOUS,
            ],
        });

        if(props.appPlaylistId === props.id) {
            const playerCurrentTrackId = await TrackPlayer.getCurrentTrack();
            if (playerCurrentTrackId) {
                const playerCurrentTrack = (props.playlist||[]).find(({id}) => id === playerCurrentTrackId);
                if(playerCurrentTrack){
                    setCurrentTrack(playerCurrentTrack);
                }
                else{
                    setCurrentTrack(currentTrackIndex && props.playlist[currentTrackIndex] ? props.playlist[currentTrackIndex] : firstTrack);
                }
            }
        }
        else if (currentTrackIndex > 0){
            setCurrentTrack(currentTrackIndex && props.playlist[currentTrackIndex] ? props.playlist[currentTrackIndex] : firstTrack);
        }
    }
    start();

    const prev = async() => {
        if(props.appPlaylistId === props.id){
            await TrackPlayer.skipToPrevious();
            await TrackPlayer.play();
        }
        else{
            const prevIndex = currentTrackIndex - 1;
            if(props.playlist[prevIndex]){
                setCurrentTrackIndex(prevIndex);
            }
        }
    }
    const play = async() => {
        if(props.appPlaylistId !== props.id){
            const thisTrack = currentTrackIndex && props.playlist[currentTrackIndex] ? props.playlist[currentTrackIndex] : firstTrack;
            await TrackPlayer.stop();
            await TrackPlayer.add(thisTrack);
            await TrackPlayer.skip(thisTrack.id);
            const tracksQueue = await TrackPlayer.getQueue();
            if(tracksQueue && tracksQueue.length > 0){
                const queueIndexes = Object.keys(tracksQueue).map(queueIndex => {return parseInt(queueIndex)});
                await TrackPlayer.remove(queueIndexes);
            }
            await TrackPlayer.add(props.playlist||[]);
            await TrackPlayer.skip(thisTrack.id);
            props.setAppPlaylistId(props.id);
        }
        await TrackPlayer.play();

    }

    const stop = async() => {
        if(props.appPlaylistId === props.id){
            await TrackPlayer.stop();
        }
    }

    const next = async() => {
        if(props.appPlaylistId === props.id){
            await TrackPlayer.skipToNext();
            await TrackPlayer.play();
        }
        else{
            const nextIndex = currentTrackIndex + 1;
            if(props.playlist[nextIndex]){
                setCurrentTrackIndex(nextIndex);
            }
        }
    }

    const indicatorWidth = props.appPlaylistId === props.id && (position && duration) ? (position / duration * 100) + '%' : '0%';
    const buttonStyle = {
        display: 'inline-block',
        width: '25%'
    };
    return (
        <View>
            <Text style={[musicStyles.title]}>
                {currentTrack.title} by {currentTrack.artist}
            </Text>
            <Image source={{ uri: currentTrack.cover }} style={{ width: '100%', height: '350px', resizeMode: 'contain' }} />
            <View style={[musicStyles.indicatorsWrap]}>
                <Text style={[musicStyles.indicators]}>
                    {position}
                </Text>
                <Text style={[musicStyles.indicators]}>{duration}</Text>
            </View>
            <View style={musicStyles.indicatorBg}>
                <View style={{ width: indicatorWidth, background: '#336699', minHeight: 10 }} />
            </View>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                <Button
                    containerStyle={buttonStyle}
                    type="solid"
                    icon={<Icon name="backward" size={18} color="white" style={{ marginRight: 10 }}/>}
                    onPress={prev}
                />
                <Button
                    containerStyle={buttonStyle}
                    type="solid"
                    icon={<Icon name="play" size={18} color="white"/>}
                    onPress={play}
                />
                <Button
                    containerStyle={buttonStyle}
                    type="solid"
                    icon={<Icon name="stop" size={18} color="white" style={{ marginRight: 10 }}/>}
                    onPress={stop}
                />
                <Button
                    containerStyle={buttonStyle}
                    type="solid"
                    icon={<Icon name="forward" size={18} color="white" style={{ marginRight: 10 }}/>}
                    onPress={next}
                />
            </View>
        </View>
    )

}
import React, { useState, useEffect } from 'react';
import { View, Text, TouchableHighlight } from 'react-native';
import { CheckBox } from "react-native-elements";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AsyncStorage from '@react-native-community/async-storage';
import { getStyle } from '../../styles/container';
import { listenFirestoreQuery, firebaseServiceGet } from '../../services/firebase';
import { ImageComponent } from './image';
import { setContent } from '../../reducers/actions';



export const ContentSlider = props => {

    const globalStyles = getStyle('globalStyles', props.app);
    const [pages, setPages] = useState([]);

    const slideStyle = {
        flex: 1,
        padding: 5,
        background: 'white',
        borderRadius: 5,
        margin: 3,
        border: '1px solid #eee',
        boxShadow: "0 0 3px #ddd",
        marginRight: 7,
        height: 150,
        overflow: 'hidden'
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: pages.length >= 3 ? 3 : pages.length,
        slidesToScroll: 2,
    };
    const imageWidth = 70;
    const sliderWrapStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    }

    useEffect(() => {

        if (props.category) {
            const callback = (response) => {
                console.log("resp..", response)
                if (response.length > 0) {
                    setPages(response);
                }
            }
            listenFirestoreQuery({
                collection: 'pages',
                callback,
                where: ['category', '==', props.appId + '-' + props.category],
                limit: 20
            })
        }
    }, []);

    return (
        <View>
            <Text style={globalStyles.h3}>{props.title || "Title goes here"}</Text>
            <Slider {...settings}>

                {(pages || []).map((page) => {

                    return <TouchableHighlight onPress={() => {
                        firebaseServiceGet({
                            collection: 'pages',
                            docId: page.id,
                        }).then(function (page) {
                            props.dispatch(setContent(page));
                            window.top.postMessage({ type: 'navigate', key: page.id }, '*');
                            AsyncStorage.setItem('pageId', page.id);
                        });
                    }} >
                        <View style={slideStyle}>
                            <Text style={globalStyles.h4}>{page.name}</Text>
                            {page.pageThumb ? <ImageComponent src={page.pageThumb} width={imageWidth} /> : null}
                            {page.description ? <Text style={globalStyles.p}>{page.description}</Text> : null}
                        </View>
                    </TouchableHighlight>
                })}
            </Slider></View>
    );
}




export const menuOverlay = {
    wrap: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 50,
        backgroundColor: '#ffffff',
        flex: 1,
        flexDirection: 'column',
        paddingTop: 50,
        textAlign: 'left',
        color: '#333333',
        fontSize: 24,
        width: '100%',
        maxWidth: 364,
        right: 0,
        alignSelf: 'stretch',
        top: 0,
        marginTop: -20
    },
    items: {
        padding: 20,
        alignSelf: 'stretch',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        backgroundColor: 'rgba(220, 220, 220, 0.1)',
        marginBottom: 1,
    },
    title: {
        paddingLeft: 30,
        paddingTop: 3,
        fontSize: 16,
    },
    iconRight: { position: 'absolute', right: 0, padding: 0 },
};

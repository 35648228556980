import React from 'react';
import {
  StyleSheet,
  Platform,
  Image,
  Text,
  View,
  TouchableHighlight,
  ScrollView,
} from 'react-native';
import { getStyle } from '../../styles/container';
import { Icon } from 'react-native-elements';
import DeviceInfo from 'react-native-device-info';
import AsyncStorage from '@react-native-community/async-storage';

import { firebaseServiceGet, listenFirestoreQuery } from '../../services/firebase';
import {
  setContent,
  changeTitleIcon,
  displaySplashPage,
  setMenuItemSelected,
  setPreviousPage,
  setCurrentPage,
  changeTitle,
  setLoading
} from '../../reducers/actions';
import { showTitleText, showIcon } from '../../../app-settings';

import { processMenu } from '../../utils/processors';

export const Header = ({ elements, pressStatus, app, title, dispatch, previousPage, titleIcon }) => {
  const globalStyles = getStyle('globalStyles', app);
  const headerTypeStyles = getStyle('headerType', app);
  const model = parseInt(DeviceInfo.getModel().replace('iPhone', ''));
  function onClick(docId, pagename, i) {
    window.top.postMessage({ type: 'navigate', key: docId }, '*');
    AsyncStorage.setItem('pageId', docId);
    dispatch(setLoading({ loading: 'fadeOutLoading' }));
    dispatch(setMenuItemSelected({ order: i }));
    dispatch(changeTitle({ title: pagename }));
    listenFirestoreQuery({
      collection: 'pages',
      docId,
      callback: (page) => {

        dispatch(setContent(page));
        dispatch(setPreviousPage({ previousPage: false }));
        dispatch(setCurrentPage({ currentPage: false }));
        dispatch(changeTitle({ title: false }));
        dispatch(changeTitleIcon({ titleIcon: false }));
        dispatch(setLoading({ loading: false }));
      }
    });
  }
  function onPressButtonMenu() { }
  if (!elements || elements.length === 0) {
    return null;
  }

  const headerType = (app.headerType || 'simple').replace('-', '');
  const paddingTop = model <= 8 ? globalStyles.littlePaddingTop : {};
  const headerNoPadding =
    model <= 8 ? headerTypeStyles[headerType + 'HeaderNoPadding'] : {};
  const titleImage = titleIcon || app.logo || false;

  const borderWhiteMenuBg = ['white', '#ffffff'].includes(globalStyles.header.backgroundColor) ? '1px solid #eee' : 'none';
  return (
    <View
      style={[
        globalStyles.header,
        headerTypeStyles[headerType + 'Header'],
        paddingTop,
        headerNoPadding,
        {
          position: "absolute",
          zIndex: 999999,
          overflow: 'hidden',
          borderBottom: borderWhiteMenuBg,
          overflow: 'hidden'
        }
      ]}
    >
      <View
        style={[
          globalStyles.menuWrapper,
          headerTypeStyles[headerType + 'MenuWrapper'],
        ]}
      >
        {app.headerType !== 'simple' &&
          elements
            .filter(page => !(app.hiddenPages || []).includes(page.id))
            .filter(page => page.position !== 'bottom')
            .map((t, i) => {
              const isHomeButtonStyle =
                app.headerType === 'simple-home'
                  ? headerTypeStyles.homeButton
                  : {};
              const item = (
                <TouchableHighlight
                  key={t.key}
                  onPress={() => {
                    if (i === 0) {
                      onClick(previousPage || t.pageId, t.content, t.orderTopMenu);
                    } else {
                      onClick(t.pageId, t.content, t.orderTopMenu);
                    }
                  }}
                  underlayColor="white"
                  style={[
                    globalStyles.menuItem,
                    headerTypeStyles[headerType + 'MenuItem'],
                    isHomeButtonStyle,
                    {
                      borderBottom: borderWhiteMenuBg,
                      borderLeft: i !== 0 ? borderWhiteMenuBg : 'none',
                      paddingTop: headerType !== 'multipletitles' ? 15 : 22
                    }
                  ]}
                >
                  <View>
                    {!previousPage ? <Icon
                      name={(t.icon || "fa-home").replace("far ", "").replace("fab ", "").replace("fa-", "")}
                      type="font-awesome"
                      color={globalStyles.menuIcon.color || globalStyles.menuIcon.color}
                    /> : <Icon
                      name={("fa-chevron-left")}
                      type="font-awesome"
                      color={globalStyles.menuIcon.color || globalStyles.menuIcon.color}
                    />}
                    {app.headerType === 'multiple-titles' ? (
                      <Text style={globalStyles.menuItemText}>{t.content}</Text>
                    ) : null}
                  </View>
                </TouchableHighlight>
              );

              if (app.headerType === 'simple-home' && i > 0) {
                return null;
              }
              if (i > 3) {
                return null;
              }
              return item;
            })}
        {app.headerType === 'simple' || app.headerType === 'simple-home' ? (
          <View style={{
            alignItems: "stretch", flex: 1,
            flexDirection: 'row', justifyContent: "center"
          }}>
            {showTitleText ? <Text
              key={'app-title'}
              style={[globalStyles.h3, headerTypeStyles.appName, { width: '100%', paddingTop: 8, color: globalStyles.menuItemText.color }]}
            >
              {titleImage && showIcon ? (<Image source={{ uri: titleImage }} style={{ width: 30, height: 30, marginTop: 5, alignSelf: "center" }} />) : null} {title}
            </Text> : null
            }
          </View>
        ) : null}
        <TouchableHighlight
          key="menu-link"
          onPress={() => {
            dispatch(setLoading({ loading: ['slideInLeftMenu', 'fadeOutContentPartly'] }));
            dispatch(displaySplashPage('menu'));
          }}
          underlayColor="white"
          style={[
            globalStyles.menuItem,
            headerTypeStyles.burgerButton,
            headerTypeStyles[headerType + 'BurgerButton'],
            {
              maxHeight: headerType !== 'multipletitles' ? 50 : 75,
              borderLeft: borderWhiteMenuBg,
              borderBottom: borderWhiteMenuBg,
              maxWidth: 50,
              paddingTop: headerType !== 'multipletitles' ? 15 : 22
            }
          ]}
        >
          <View>
            <Icon
              name="bars"
              type="font-awesome"
              color={globalStyles.menuIcon.color}
            />
            {app.headerType === 'multiple-titles' ? (
              <Text style={globalStyles.menuItemText}>Menu</Text>
            ) : null}
          </View>
        </TouchableHighlight>
      </View>
    </View>
  );
};

import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Animated,
  Easing,
  Dimensions,
  Image,
  Text,
  ImageBackground,
} from "react-native";
import { Button } from "react-native-elements";

import AsyncStorage from "@react-native-community/async-storage";
import { requestTrackingPermission } from "react-native-tracking-transparency";
import { SettingsPage } from "../../modules/cryptocurrency/settings";
import { cryptomodule } from "../../../app-settings";
import { setLoading } from "../../reducers/actions";

async function requestUserPermission(props) {
  confirm("Do you allow us to send notifications?");
  AsyncStorage.setItem("intro", JSON.stringify({ step: "done" }));
  props.setIntro({ step: "done" });
}

export const Intro = (props) => {
  const [introObj, setIntroObj] = useState(props.intro);
  const stepTwo =
    props.intro.step === 2 ? (
      <View
        style={[
          props.globalStyles.mainContent,
          { padding: 20, paddingTop: 70 },
        ]}
      >
        <Text
          style={[
            props.globalStyles.title,
            props.globalStyles.centered,
            { color: props.globalStyles.p.color },
          ]}
        >
          Notifications
        </Text>
        <Text style={[props.globalStyles.h2, props.globalStyles.centered]}>
          We would like to be able to send you push notifications
        </Text>
        <Text style={[props.globalStyles.h2, props.globalStyles.centered]}>
          You can add and remove alerts later on from the Alerts page
        </Text>
        <Image
          source={{
            uri:
              "https://storage.googleapis.com/graphics-app-builder/email-notifications.jpeg",
          }}
          style={{ alignSelf: "center", width: 250, height: 161 }}
        />
        <Button
          color="#ffffff"
          title={"Choose"}
          titleStyle={{ fontWeight: "600", color: "#333" }}
          buttonStyle={props.globalStyles.whiteButtonStyle}
          accessibilityLabel={"Choose"}
          onPress={() => {
            requestUserPermission(props);
          }}
        />
        {/**
         * <Button
            color="#ffffff"
            title={"Go back"}
            type="clear"
            titleStyle={{ fontWeight: '600', color: '#fff' }}
            buttonStyle={props.globalStyles.outlineButtonStyle}
            accessibilityLabel={"Go back"}
            onPress={() => {
                setIntroObj({ step: 1 });
                props.setIntro({ step: 1 });
                AsyncStorage.setItem('intro', JSON.stringify({ step: 1 }));
            }}
        />
         */}
        <Text style={[props.globalStyles.h2, props.globalStyles.centered]}>
          Note: if you don't allow notifications you won't be able to set up
          Alerts!
        </Text>
      </View>
    ) : null;
  const stepOne = (
    <View
      style={[props.globalStyles.mainContent, { padding: 20, paddingTop: 70 }]}
    >
      <Text
        style={[
          props.globalStyles.title,
          props.globalStyles.centered,
          { color: props.globalStyles.p.color },
        ]}
      >
        Welcome!
      </Text>
      <Text style={[props.globalStyles.h2, props.globalStyles.centered]}>
        Let's first set your preferences
      </Text>
      {cryptomodule ? (
        <SettingsPage centered={true} showIntroInfo={true} />
      ) : null}
      <Button
        status="success"
        title={"Continue"}
        titleStyle={{ fontWeight: "600", color: "#333" }}
        buttonStyle={props.globalStyles.whiteButtonStyle}
        accessibilityLabel={"Continue"}
        onPress={() => {
          props.dispatch(setLoading({ loading: "fadeOutLoading" }));
          setTimeout(() => {
            props.dispatch(setLoading({ loading: "slideInLeft" }));
            setIntroObj({ step: 2 });
            props.setIntro({ step: 2 });
            AsyncStorage.setItem("intro", JSON.stringify({ step: 2 }));
            props.dispatch(setLoading({ loading: false }));
          }, 500);
        }}
      />
      {stepTwo}
    </View>
  );

  useEffect(() => {
    if (introObj.step !== 2 && introObj.step !== "done") {
      console.log("trackingStatus....");
      /*
            requestTrackingPermission().then((trackingStatus) => {
                console.log("trackingStatus", trackingStatus)
                if (trackingStatus === 'authorized' || trackingStatus === 'unavailable') {
                    // enable tracking features
                }
            });
            */
    }
  }, []);

  //return introObj.step === 2 ? stepTwo : stepOne;
  return stepTwo;
};

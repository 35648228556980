import React, { useRef, useEffect, useState } from "react";
import { Text, TouchableOpacity, View, Dimensions, ImageBackground } from "react-native";
import { Icon } from "react-native-elements";
import Swiper from "react-native-web-swiper";
import { globalStyles } from "../../styles/global";
import { GoToPage } from "./goToPage";

const CarouselComp = (props) => {

    const height = parseInt(props.height || (Dimensions.get('window').height - 160));
    const slideStyle = {
        flex: 1,
        justifyContent: "center"
    };
    const [swiperObj, setSwiperObj] = useState(null);
    const swiperRef = useRef(null);
    useEffect(() => {

        setSwiperObj(null);
        setTimeout(() => {
            setSwiperObj(<Swiper
                ref={swiperRef}
                from={0}
                minDistanceForAction={0.1}
                controlsProps={{
                    dotsTouchable: true,
                    prevPos: 'left',
                    nextPos: 'right',
                    NextComponent: ({ onPress }) => (
                        <TouchableOpacity onPress={onPress}>
                            <Icon type="font-awesome" name="caret-right" color="white" size={40} ></Icon>
                        </TouchableOpacity>
                    ),
                    PrevComponent: ({ onPress }) => (
                        <TouchableOpacity onPress={onPress}>
                            <Icon type="font-awesome" name="caret-left" color="white" size={40} ></Icon>
                        </TouchableOpacity>
                    ),
                }}
            >
                {(props.data || []).map((slide) => {
                    return <ImageBackground source={slide.image} resizeMode="cover" style={slideStyle}>
                        <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: slide.image ? 'transparent' : slide.color || "white" }}>
                            {slide.title ? <Text style={[globalStyles.bold, globalStyles.h2, globalStyles.whiteColor, globalStyles.centered, { width: '75%', textShadowColor: '#6d6c6c', textShadowOffset: { width: 1, height: 1 } }]} >{slide.title}</Text> : null}
                            {slide.buttonTitle ? <View style={{ position: 'absolute', bottom: 50, }}> <GoToPage dispatch={props.dispatch} atts={{ content: slide.buttonTitle || "Go", pageId: slide.pageId, key: "call-to-action" }} /></View> : null}
                        </View></ImageBackground>
                })}
            </Swiper>)
        }, 100)

    }, [props.data]);

    return (props.data && props.data.length > 0 ?
        <View style={{ display: 'relative', height }}><View style={{ flex: 1 }}>
            <View style={{ flex: 1 }}>
                {swiperObj}
            </View>
        </View>
        </View > : <View><Text style={globalStyles.h3}>No Slides Found</Text></View>
    )

}
export default CarouselComp;
import React from "react";
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Animation
import { easeQuad } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { View, Text, Dimensions } from 'react-native';
import { getStyle } from '../../styles/container';


export const Loading = (props) => {
    const globalStyles = getStyle('globalStyles', props.app);
    console.log("props.loadingAnimation", props.loadingAnimation);
    let showLoading = false;
    if (props.loadingAnimation) {
        if (props.loadingAnimation.includes('slideInLeftMenu')) {
            showLoading = false;
        } else {
            showLoading = true;
        }
    }
    return showLoading ? <View style={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: (Dimensions.get('window').height / 2) - 50,
        zIndex: 999999999,
        width: '100px',
        left: (Dimensions.get('window').width / 2) - 50
    }}>
        <AnimatedProgressProvider
            valueStart={0}
            valueEnd={100}
            duration={1.4}
            easingFunction={easeQuad}
            repeat
        >
            {value => {
                const roundedValue = Math.round(value);
                return (
                    <CircularProgressbar
                        value={value}
                        text={props.loadingAnimation.includes('fadeOutWithText') ? props.loadingMessage || 'Loading' : null}
                        /* This is important to include, because if you're fully managing the
                  animation yourself, you'll want to disable the CSS animation. */
                        styles={buildStyles({ pathTransition: "none" })}
                    />
                );
            }}
        </AnimatedProgressProvider></View> : null

}
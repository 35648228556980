export const loginPage = {
  wrapper: {
    paddingBottom: 20,
    backgroundColor: '#fff',
    flex: 1,
    flexDirection: 'column',
    paddingTop: 20,
    textAlign: 'center',
    alignItems: 'center',
    color: 'white'
  },
  genericButton: {
    padding: 10,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 10,
    width: 250,
  },
};

import React from 'react';
import { connect } from 'react-redux';
import { MenuOverlay } from '../../components/splash-page/menu-overlay';

const mapStateToProps = state => {
  return {
    pagename: state.content.pagename || 'unknown',
    app: state.app,
  };
};

export default connect(mapStateToProps)(MenuOverlay);

import {
    useReachability,
    sendMessageData,
    sendMessage,
    startFileTransfer,
    useInstalled,
} from 'react-native-watch-connectivity';

export const sendToWatch = (prices) => {
    try {

        // [[cc.symbol, cc.name], [price, changed]]
        const coins = prices.reduce((acc, price) => {
            const symbol = price[0][0];
            if (["BTC", "ETH", "XRP"].includes(symbol)) {
                const selectorPrice = (price[0][0]).toLowerCase() + 'Price';
                acc[selectorPrice] = price[1][0];
                const selectorPriceChanged = (price[0][0]).toLowerCase() + 'PriceChanged';
                acc[selectorPriceChanged] = price[1][1];
            }
            return acc;
        }, {});
        console.log("coins", coins);
        sendMessage(coins)
    } catch (e) {
        console.log(e);
    }
}
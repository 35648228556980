import React, { useState } from 'react';
import {

  Text,
  View,
  TouchableHighlight,
  Linking
} from 'react-native';
import { Icon } from 'react-native-elements';

import DeviceInfo from 'react-native-device-info';
import AsyncStorage from '@react-native-community/async-storage';
import { getStyle } from '../../styles/container';
import {

  setContent,
  setMenuItemSelected,
  setPreviousPage,
  setLoading,
  changeTitle
} from '../../reducers/actions';
import { processMenu } from '../../utils/processors';
import { firebaseServiceGet, listenFirestoreQuery } from '../../services/firebase';
import { signOut } from '../../services/auth';

export const Footer = ({ loadAppsStatus, dispatch, app, menuItemSelected }) => {
  const globalStyles = getStyle('globalStyles', app);
  const footerStyle = getStyle('footer', app);

  function onClick(docId, pagename, i) {
    window.top.postMessage({ type: 'navigate', key: docId }, '*');
    AsyncStorage.setItem('pageId', docId);
    dispatch(setMenuItemSelected({ order: i }));
    dispatch(changeTitle({ title: pagename }));
    listenFirestoreQuery({
      collection: 'pages',
      docId,
      callback: (page) => {
        dispatch(setContent(page));
        dispatch(setPreviousPage({ previousPage: false }));
        dispatch(setLoading({ loading: false }));
      }
    });
  }
  function stripIcon(i) {
    return i
      .replace('far fa-', '')
      .replace('fab fa-', '')
      .replace('fa-', '');
  }
  const pages = ([...app.pages, ...(app.extraPages || [])] || []).sort((a, b) =>
    a.orderTopMenu > b.orderTopMenu
      ? 1
      : b.orderTopMenu > a.orderTopMenu
        ? -1
        : 0
  ).slice(0, 6);

  const bottomMenuitems = app.pages
    .filter(page => !(app.hiddenPages || []).includes(page.id))
    .filter(page => page.position === 'bottom')
    .map((t, i) => {
      const item = (
        <TouchableHighlight
          key={t.key}
          onPress={() => {
            //setMenuItemSelected(i);
            if (t.link) {
              dispatch(setLoading({ loading: ['fadeOutWithText', 'fadeOutLoading'], message: t.redirectTitle || 'Redirecting..' }));
              setTimeout(() => {
                dispatch(setLoading({ loading: false, message: false }));
                Linking.openURL(t.link)
              }, 2000);
            } else {
              onClick(t.id, t.menuTitle, i + 1);
            }
          }}
          underlayColor={footerStyle.wrap.backgroundColor}
        >
          <View
            style={[
              globalStyles.menuItem,
              globalStyles.centered,
              footerStyle.menuItem,
              menuItemSelected === (i + 1)
                ? globalStyles.menuItemSelected
                : {},
              { paddingTop: app.footerType === 'titles' ? 5 : 10, height: 48, width: 52 }
            ]}
          >
            <Icon
              name={stripIcon(t.icon)}
              color={footerStyle.wrap.color || '#ffffff'}
              type="font-awesome-5"
            />
            {!app.footerType || app.footerType === 'titles' ? (
              <Text style={[footerStyle.menuItemText, { color: footerStyle.wrap.color || '#ffffff' }]}>{t.menuTitle}</Text>
            ) : null}
          </View>
        </TouchableHighlight>
      );
      return item;
    });


  const model = parseInt(DeviceInfo.getModel().replace('iPhone', ''));
  const paddingBottom = model <= 8 ? footerStyle.noPaddingBottom : {};
  if (app.footerType !== 'hide') {
    return (
      <View style={[footerStyle.wrap, paddingBottom,
      {
        position: "absolute",
        zIndex: 999999,
        bottom: 128,
        width: '100%'
      }]}>{bottomMenuitems}</View>
    );
  } else {
    return null;
  }
};

import React, { useState, useEffect } from 'react';
import { View, Button, Text, TouchableHighlight, Linking, Dimensions } from 'react-native';
import { Chart, Line, Area, HorizontalAxis, VerticalAxis, Tooltip } from 'react-native-responsive-linechart';
import { getStyle } from '../../styles/container';
import { cryptoBuyLink } from '../../../app-settings';
import { helperGetDate, getPrice } from './utils';


export const CoinPageWrap = props => {

    const globalStyles = getStyle('globalStyles', props.app);
    const stats = props.coinData[props.page];
    const [period, setPeriod] = useState(7);
    const periods = [7, 30, 365, 700];
    const periodDescription = { 7: "1W", 30: "1M", 365: "1Y", 700: "MAX" };
    const coinAssets = props.assets.data.filter(asset => asset.id == props.page)[0] || {};
    const statsReversed = stats ? (stats.slice(stats.length - period, stats.length)).reverse() : [];

    let xMinDomain = 0; let xMaxDomain = 0;
    let yMinDomain = 0; let yMaxDomain = 0;
    let xMaxDomainFull = 0;
    let todayRate;

    const currentPrice = getPrice({ priceUsd: coinAssets.priceUsd }, props.rates, props.currentCurrency);

    const today = helperGetDate(new Date(props.assets.timestamp));
    const [indicator, setIndicator] = useState({ date: today, price: currentPrice })
    const [tooltipX, changeTooltipX] = useState(0);
    const data = statsReversed.map((day, index) => {
        const priceUsd = getPrice({ priceUsd: day.priceUsd }, props.rates, props.currentCurrency, 'number');
        const dayDate = new Date(day.date).getTime();
        if (index === statsReversed.length - 1) {
            todayRate = parseFloat(priceUsd);
        }
        xMinDomain = (xMinDomain === 0 || xMinDomain > dayDate) ? dayDate : xMinDomain;
        xMaxDomain = xMaxDomain < dayDate ? dayDate : xMaxDomain;
        yMinDomain = (yMinDomain > parseFloat(priceUsd) || yMinDomain === 0) ? parseFloat(priceUsd) : yMinDomain;

        yMaxDomain = yMaxDomain < parseFloat(priceUsd) ? parseFloat(priceUsd) : yMaxDomain;
        return { x: dayDate, y: parseFloat(priceUsd) }
    });

    const linkStyle = { width: '20%', background: '#999999', textDecoration: 'none' };
    const darkListStyle = {
        flexDirection: "row",
        flex: 1,
        marginBottom: 5,
        justifyContent: 'space-between',
        alignItems: 'center',
    };
    const yMinFinal = parseFloat(yMinDomain - (yMaxDomain / 5)) < 0 ? 0 : parseFloat(yMinDomain - (yMaxDomain / 5));
    console.log("yMinFinal", yMinFinal);

    const chart = statsReversed.length > 0 ? (<Chart
        style={{ height: 240, width: '100%', color: 'yellow', borderBottomColor: "yellow" }}
        padding={{ top: 10, left: 0, right: 0, bottom: 0 }}
        data={data}

        xDomain={{
            min: parseInt(xMinDomain),
            max: parseInt(xMaxDomain)
        }}
        yDomain={{
            min: parseFloat(yMinFinal),
            max: parseFloat(yMaxDomain)
        }}

    >
        <VerticalAxis tickCount={5} theme={{
            grid: {
                visible: true,
                stroke: {
                    color: '#555',
                },
            },
            labels: {
                color: 'yellow', formatter: (v) => null
            }
        }} />
        <HorizontalAxis tickCount={5} theme={{
            grid: {
                visible: true,
                stroke: {
                    color: '#555',
                },
            },
            labels: {
                color: 'yellow', formatter: (x) => null
            }
        }} />
        <Area tension={0.3} theme={{
            label: {
                color: 'yellow',
                fontSize: 12,
                fontWeight: 700,
                textAnchor: 'middle',
                opacity: 1,
                dx: 0,
                dy: 16.5,
            },
            grid: {
                visible: true,
                backgroundColor: "#000",
                strokeWidth: 1,
                strokeColor: "#000",
                stepSize: 15
            }, gradient: { from: { color: '#44bd32' }, to: { color: '#44bd32', opacity: 0.2 } }
        }} />
        <Line
            tooltipComponent={<Tooltip theme={{
                label: {
                    color: 'yellow',
                    fontSize: 12,
                    fontWeight: 700,
                    textAnchor: 'middle',
                    opacity: 1,
                    dx: tooltipX,
                    dy: -25,
                },
                shape: {
                    width: 80,
                    height: 30,
                    dx: tooltipX,
                    dy: -20,
                    rx: 4,
                    color: 'rgba(0, 0, 0, 0.3)',
                },
                formatter: (v: ChartDataPoint) => {


                    const date = helperGetDate(new Date(v.x))
                    const price = getPrice({ priceUsd: v.y }, props.rates, props.currentCurrency, 'skip-currency-calc')

                    if (indicator.date !== date) {
                        setIndicator({
                            date: date,
                            price: price
                        });

                        let tooltipX = 0;
                        if (new Date(date).toISOString() === new Date(xMaxDomain).toISOString()) {
                            tooltipX = -35;
                        }
                        if (new Date(date).toISOString() === new Date(xMinDomain).toISOString()) {
                            tooltipX = 35;
                        }
                        console.log("tooltipX6666", new Date(date).toISOString(), new Date(xMaxDomain).toISOString());
                        changeTooltipX(tooltipX)
                    }

                    return price.toString();
                },
            }} />}
            theme={{ stroke: { color: '#44bd32', width: 1 }, scatter: { default: { width: 1, height: 1, rx: 4, color: '#44ad32' }, selected: { color: 'yellow', width: 8, height: 8, } } }}
        />
    </Chart>) : null;

    const periodSelections = statsReversed.length > 0 ? (<View style={{
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
        marginBottom: 15
    }}>
        {periods.map(p => {
            const linkStyleHover = p === period ? globalStyles.tabsHover : {};
            return (<TouchableHighlight
                style={linkStyle}
                key={"select-perioid-" + p}
                onPress={() => {
                    setPeriod(p);
                }}
                underlayColor={globalStyles.mainContent.backgroundColor}
            >
                <Text style={[globalStyles.p, globalStyles.link, globalStyles.tabs, linkStyleHover]}>
                    {periodDescription[p]}
                </Text>
            </TouchableHighlight>)
        }
        )}

    </View>) : null;


    return (

        <View>
            <Text style={[globalStyles.h1, globalStyles.centered, { marginTop: 30, marginBottom: 0 }]}>
                {indicator.price}
            </Text>
            <Text style={[globalStyles.h3, globalStyles.centered, { marginTop: 0 }]}>
                {indicator.date}
            </Text>


            {chart}

            { periodSelections}


            <View style={[globalStyles.darkListItem, darkListStyle]}>
                <Text style={[globalStyles.h3]}>Current price: </Text>
                <Text style={[globalStyles.h3]}>
                    {currentPrice}
                </Text></View>
            <View style={[globalStyles.darkListItem, darkListStyle]}>
                <Text style={[globalStyles.h3, { textAlign: 'left' }]}>Market capitalization:</Text>
                <Text style={[globalStyles.h3, { textAlign: 'right' }]}>{getPrice({ priceUsd: coinAssets.marketCapUsd }, props.rates, props.currentCurrency)}</Text></View>
            <View style={[globalStyles.darkListItem, darkListStyle]}>
                <Text style={[globalStyles.h3, { textAlign: 'left' }]}>Change in last 24 hours:</Text>
                <Text style={[globalStyles.h3, { textAlign: 'right' }]}> {(parseFloat(coinAssets.changePercent24Hr) > 0 ? '+' + parseFloat(coinAssets.changePercent24Hr).toFixed(2) : parseFloat(coinAssets.changePercent24Hr).toFixed(2)) + '%'}</Text></View>
            <View style={[globalStyles.darkListItem, darkListStyle]}>
                <Text style={[globalStyles.h3, { textAlign: 'left' }]}>Maximum supply:</Text>
                <Text style={[globalStyles.h3, { textAlign: 'right' }]}>{coinAssets.maxSupply ? parseInt(coinAssets.maxSupply) : "N/A"}</Text></View>
            <View style={[globalStyles.darkListItem, darkListStyle]}>
                <Text style={[globalStyles.h3, { textAlign: 'left' }]}>Current supply: </Text>
                <Text style={[globalStyles.h3, { textAlign: 'right' }]}>{parseInt(coinAssets.supply)}</Text></View>
            <View style={[globalStyles.darkListItem, darkListStyle]}>
                <Text style={[globalStyles.h3, { textAlign: 'left' }]}>Volume in last 24 hour (in USD): </Text>
                <Text style={[globalStyles.h3, { textAlign: 'right' }]}>{parseInt(coinAssets.volumeUsd24Hr)}</Text></View>
            <View style={[globalStyles.darkListItem, darkListStyle]}>
                <Text style={[globalStyles.h3, { textAlign: 'left' }]}>Volume Weighted Average Price (24h): </Text>
                <Text style={[globalStyles.h3, { textAlign: 'right' }]}>{parseInt(coinAssets.vwap24Hr)}</Text></View>

            <View style={{
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: 'center',
                flex: 1,
                justifyContent: 'center'
            }}>
                <View style={[globalStyles.success, globalStyles.centered, { width: 200, borderRadius: 10, marginBottom: 10 }]} key={"see-more"}>
                    <Button
                        color="#ffffff"
                        title={"Buy with PayBis"}
                        accessibilityLabel={"Buy with PayBis"}
                        onPress={() => {

                            Linking.openURL(cryptoBuyLink);
                        }}
                    />
                </View>
            </View>

        </View >

    );
};

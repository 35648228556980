export const cryptoStyle = {
    plusMinusIcons: {
        height: 19,
        width: 19,
        borderColor: "white",
        borderRadius: 5,
        borderWidth: 1,
        backgroundColor: "transparent",
        color: '#fff',
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: 2
    },
    plusMinusContainer: {
        flex: 1, marginTop: 18,
        paddingLeft: 10, flexDirection: "column"
    },
    priceContainer: { flex: 2, borderWidth: 1, height: 40, borderRadius: 5, borderColor: '#ccc', marginTop: 20, justifyContent: "space-between", flexDirection: "row" },
    picker: {
        maxHeight: 70,
        fontSize: 14,
        padding: 3,
        width: "100%",
        marginTop: 5
    }
}